import React from 'react'
import { Row, Col, Card, Button } from '@themesberg/react-bootstrap'
import ClientFilter from '../../../components/ClientFilter'
import PortalFilter from '../../../components/PortalFilter'
import Preloader from '../../../components/Preloader'
import DemographicDetails from './components/DemographicDetails'
import LivePlayers from './components/LivePlayers'
import TopPlayes from './components/TopPlayes'
import PlayerLiability from './components/PlayerLiability'
import KPISummary from './components/KPISummary'
import KPIReport from './components/KPIReport'
import GameReport from './components/GameReport'
import JackpotContribution from './components/JackpotContribution'
import useDashboard from './hooks/useDashboard'

import Accordion from 'react-bootstrap/Accordion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartLineUpDown, faWallet, faMapLocationDot, faUsersRectangle, faTableColumns } from '@fortawesome/pro-solid-svg-icons'
import { checkIsTenant } from '../../../utils/constants'

export default () => {
  const {
    loading,
    isHidden,
    reportsToShow,
    elasticHealth,
    accordionState,
    permissionKeys,
    selectedClient,
    selectedPortal,
    setSelectedClient,
    setSelectedPortal,
    adminPermissions,
    nonElasticReports,
    setAccordionState
  } = useDashboard()

  return (
    <div className='dashboard-component'>
      {loading
        ? <Preloader />
        : (adminPermissions && permissionKeys.some(x => reportsToShow.indexOf(x) >= 0) &&
          (elasticHealth || permissionKeys.some(x => nonElasticReports.indexOf(x) >= 0))
            ? (
              <>
                <Card className='p-2 mb-2'>
                  <Row>
                    <Col className='d-flex align-items-center'>
                      <h3><FontAwesomeIcon icon={faTableColumns} />&nbsp;Dashboard </h3>
                    </Col>

                    {!checkIsTenant() && (
                      <Col xs='auto' className='d-flex align-items-center'>
                        <div className='d-flex justify-content-end align-items-center'>
                          <ClientFilter
                            setSelectedClient={setSelectedClient}
                            setSelectedPortal={setSelectedPortal}
                            selectedClient={selectedClient}
                          />

                          {selectedClient && (
                            <PortalFilter
                              setSelectedPortal={setSelectedPortal}
                              selectedPortal={selectedPortal}
                              selectedClient={selectedClient}
                            />
                          )}
                        </div>
                        <small>
                          <Button
                            size='sm'
                            className='circle-button'
                            variant={elasticHealth ? 'success' : 'danger'}
                          />&nbsp;Elastic &nbsp;
                        </small>
                      </Col>
                    )}
                  </Row>
                </Card>
                {permissionKeys.includes('LivePlayerReport') &&
                  <LivePlayers selectedPortal={selectedPortal} selectedClient={selectedClient} />}

                <Accordion defaultActiveKey={0} alwaysOpen>
                  <Accordion.Item
                    eventKey={0}
                    key={`label-${0}`}
                    onClick={() => !accordionState.demographicReport && setAccordionState({ ...accordionState, demographicReport: true })}
                  >
                    <Accordion.Header><FontAwesomeIcon icon={faMapLocationDot} />&nbsp; Demographic Reports</Accordion.Header>
                    <Accordion.Body style={{ padding: '0' }}>
                      {permissionKeys.includes('DemographReport') &&
                        <Card className='card-simple'>
                          <DemographicDetails
                            isHidden={isHidden}
                            accordionState={accordionState}
                            selectedPortal={selectedPortal}
                            selectedClient={selectedClient}
                          />
                        </Card>}
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item
                    eventKey={1}
                    key={`label-${1}`}
                    onClick={() => !accordionState.playerManagementReport && setAccordionState({ ...accordionState, playerManagementReport: true })}
                  >
                    <Accordion.Header><FontAwesomeIcon icon={faUsersRectangle} />&nbsp; Top Performers </Accordion.Header>
                    <Accordion.Body style={{ padding: '0' }}>
                      {permissionKeys.includes('PlayerManagementReport') &&
                        <Card className='card-simple'>
                          <TopPlayes
                            isHidden={isHidden}
                            accordionState={accordionState}
                            selectedPortal={selectedPortal}
                            selectedClient={selectedClient}
                          />
                        </Card>}
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item
                    eventKey={2}
                    key={`label-${2}`}
                    onClick={() => !accordionState.playerLiabilityReport && setAccordionState({ ...accordionState, playerLiabilityReport: true })}
                  >
                    <Accordion.Header><FontAwesomeIcon icon={faWallet} />&nbsp; Player Balances</Accordion.Header>
                    <Accordion.Body style={{ padding: '0' }}>
                      {permissionKeys.includes('PlayerLiabilityReport') &&
                        <Card className='card-simple'>
                          <PlayerLiability
                            isHidden={isHidden}
                            accordionState={accordionState}
                            selectedPortal={selectedPortal}
                            selectedClient={selectedClient}
                          />
                        </Card>}
                    </Accordion.Body>
                  </Accordion.Item>

                  {elasticHealth && permissionKeys.includes('KpiSummaryReport') &&
                    <Accordion.Item
                      eventKey={3}
                      key={`label-${3}`}
                      onClick={() => !accordionState.kpiSummaryReport && setAccordionState({ ...accordionState, kpiSummaryReport: true })}
                    >
                      <Accordion.Header><FontAwesomeIcon icon={faChartLineUpDown} />&nbsp; KPI Summary</Accordion.Header>
                      <Accordion.Body style={{ padding: '0' }}>
                        <Card className='card-simple'>
                          <KPISummary
                            isHidden={isHidden}
                            accordionState={accordionState}
                            selectedPortal={selectedPortal}
                            selectedClient={selectedClient}
                          />
                        </Card>
                      </Accordion.Body>
                    </Accordion.Item>}

                  {elasticHealth && permissionKeys.includes('KpiReport') &&
                    <Accordion.Item
                      eventKey={4}
                      key={`label-${4}`}
                      onClick={() => !accordionState.kpiReport && setAccordionState({ ...accordionState, kpiReport: true })}
                    >
                      <Accordion.Header><FontAwesomeIcon icon={faChartLineUpDown} />&nbsp; Games KPI Report</Accordion.Header>
                      <Accordion.Body style={{ padding: '0' }}>
                        <Card className='card-simple'>
                          <KPIReport
                            isHidden={isHidden}
                            accordionState={accordionState}
                            selectedPortal={selectedPortal}
                            selectedClient={selectedClient}
                          />
                        </Card>
                      </Accordion.Body>
                    </Accordion.Item>}

                  {elasticHealth && permissionKeys.includes('GameReport') &&
                    <Accordion.Item
                      eventKey={5}
                      key={`label-${5}`}
                      onClick={() => !accordionState.gameReport && setAccordionState({ ...accordionState, gameReport: true })}
                    >
                      <Accordion.Header><FontAwesomeIcon icon={faChartLineUpDown} />&nbsp; Game Activity Report</Accordion.Header>
                      <Accordion.Body style={{ padding: '0' }}>
                        <Card className='card-simple'>
                          <GameReport
                            isHidden={isHidden}
                            accordionState={accordionState}
                            selectedPortal={selectedPortal}
                            selectedClient={selectedClient}
                          />
                        </Card>
                      </Accordion.Body>
                    </Accordion.Item>}

                  {elasticHealth && permissionKeys.includes('JackpotReport') &&
                    <Accordion.Item
                      eventKey={6}
                      key={`label-${6}`}
                      onClick={() => !accordionState.JackpotReport && setAccordionState({ ...accordionState, JackpotReport: true })}
                    >
                      <Accordion.Header><FontAwesomeIcon icon={faChartLineUpDown} />&nbsp; Jackpot Contribution Report</Accordion.Header>
                      <Accordion.Body style={{ padding: '0' }}>
                        <Card className='card-simple'>
                          <JackpotContribution
                            isHidden={isHidden}
                            accordionState={accordionState}
                            selectedPortal={selectedPortal}
                            selectedClient={selectedClient}
                          />
                        </Card>
                      </Accordion.Body>
                    </Accordion.Item>}
                </Accordion>
              </>
              )
            : (
              <>
                <Card className='p-2 mb-2'>
                  <Row>
                    <Col>
                      <h3>Dashboard </h3>
                    </Col>
                  </Row>
                </Card>

                <Card style={{ height: '79vh' }}>
                  <img className='m-auto' src='/nodata2.png' alt='nodata' />
                </Card>
              </>
              )
          )}
    </div>
  )
}
