import { Col, Form, Row } from '@themesberg/react-bootstrap'
import React from 'react'
import Preloader from '../../../../components/Preloader'
import ProviderFilter from '../../../../components/ProviderFilter'
import './bannedGamesStyle.css'
import useBannedGamesDetails from '../hooks/useBannedGamesDetails'
import BannedGamesDetailsListing from './BannedGamesDetailsListing'

const BannedGamesDetails = () => {
  const {
    loading,
    getBannedGamesListDetail,
    selectedProvider,
    setSelectedProvider,
    totalPages,
    limit,
    pageNo,
    setLimit,
    setPageNo,
    search,
    setSearch,
    isTenant,
    isClone,
    listBannedGames,
    setListBannedGames
  } = useBannedGamesDetails()

  return (
    <>
      <Row>
        <Col>
          <h3>Banned Games: {loading ? '' : getBannedGamesListDetail?.bannedGamesListName} </h3>
        </Col>
        <Col>
          <div className='d-flex justify-content-end align-items-center'>
            <ProviderFilter
              selectedProvider={selectedProvider}
              setSelectedProvider={setSelectedProvider}
              isTenantRoute={isTenant}
            />
          </div>
        </Col>
        <Col xs='auto'>
          <div className='d-flex justify-content-end align-items-center'>
            <Form.Label style={{ marginRight: '15px', marginBottom: '0px' }}>
              Search
            </Form.Label>

            <Form.Control
              type='search'
              size='sm'
              style={{ maxWidth: '230px' }}
              placeholder='Search Game Name'
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </Col>
      </Row>

      <BannedGamesDetailsListing
        pageNo={pageNo}
        setPageNo={setPageNo}
        totalPages={totalPages}
        limit={limit}
        setLimit={setLimit}
        getBannedGamesListDetail={getBannedGamesListDetail.gameDetail}
        isTenant={isTenant}
        isClone={isClone}
        listBannedGames={listBannedGames}
        setListBannedGames={setListBannedGames}
        loading={loading}
      />
    </>
  )
}

export default BannedGamesDetails
