import React from 'react'
import useGroups from './hooks/useGroups'
import PaginationComponent from '../Pagination'
import { Row, Col, Button, Table, ButtonGroup } from '@themesberg/react-bootstrap'
import { SuperAdminRoutes, TenantRoutes } from '../../routes'
import Trigger from '../OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faEye } from '@fortawesome/pro-solid-svg-icons'

const GroupManagement = () => {
  const {
    groupsList,
    limit,
    setLimit,
    page,
    setPage,
    // groupName,
    // setGroupName,
    isHidden,
    groupLoading,
    totalPages,
    navigate,
    isTenant
  } = useGroups()

  return (
    <>
      <Row>
        <Col>
          <h3>Group Management</h3>
        </Col>

        <Col>
          <div className='d-flex justify-content-end align-items-center'>
            {/* <Form.Label style={{ marginRight: '15px', marginTop: '8px' }}>
              Search
            </Form.Label>

            <Form.Control
              type='search'
              placeholder='Search by Group Name'
              value={groupName}
              size='sm'
              style={{ maxWidth: '230px', marginRight: '10px', maxHeight: '15px' }}
              onChange={(event) => {
                const mySearch = event.target.value.replace(/[^\w\s+@.]/gi, '')
                setGroupName(mySearch)
              }}
            /> */}
            {/* Button to create new group */}
            <Button
              variant='outline-success'
              className='mb-2 m-1'
              size='sm'
              onClick={() =>
                navigate(isTenant ? TenantRoutes.CreateGroup : SuperAdminRoutes.CreateGroup)}
              hidden={isHidden({ module: { key: 'GroupPermission', value: 'C' } })}
            >
              Create
            </Button>
          </div>
        </Col>
      </Row>

      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            {['ID', 'Group Name', 'Action'].map((h, idx) => (
              <th key={idx}>
                {h}
              </th>
            ))}
          </tr>
        </thead>

        <tbody id={groupLoading ? 'cover-spin' : ''}>
          {!groupLoading && groupsList &&
              groupsList?.rows?.map(
                ({
                  id,
                  groupName
                }, index) => {
                  return (
                    <tr key={id}>
                      <td>{index + 1}</td>

                      <td>{groupName || '-'}</td>

                      <td>
                        <ButtonGroup>
                          <Trigger message='Edit'>
                            <Button
                              className='m-1'
                              size='sm'
                              variant='warning'
                              onClick={() =>
                                navigate(
                                  `/${isTenant ? 'tenant' : 'super-admin'}/group-management/edit/${id}`
                                )}
                              hidden={isHidden({ module: { key: 'GroupPermission', value: 'U' } })}
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </Button>
                          </Trigger>

                          <Trigger message='View Details'>
                            <Button
                              className='m-1'
                              size='sm'
                              variant='info'
                              onClick={() =>
                                navigate(
                                  `/${isTenant ? 'tenant' : 'super-admin'}/group-management/details/${id}`
                                )}
                              hidden={isHidden({ module: { key: 'GroupPermission', value: 'R' } })}
                            >
                              <FontAwesomeIcon icon={faEye} />
                            </Button>
                          </Trigger>
                        </ButtonGroup>
                      </td>
                    </tr>
                  )
                }
              )}

          {!groupsList?.count && !groupLoading &&
              (
                <tr>
                  <td
                    colSpan={6}
                    className='text-danger text-center'
                  >
                    No data found
                  </td>
                </tr>
              )}
        </tbody>
      </Table>

      {groupsList?.count > 0 && !groupLoading &&
        (
          <PaginationComponent
            page={groupsList?.count < page ? setPage(1) : page}
            totalPages={totalPages}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
          />

        )}
    </>
  )
}

export default GroupManagement
