import { takeLatest, put } from 'redux-saga/effects'
import {
  getLanguages,
  getLanguageSupportKeys,
  uploadLanguageCSV,
  updateLanguageSupport,
  getCMSLanguage,
  updateErrorCodes,
  setTALanguages,
  getTALanguages
} from '../../utils/apiCalls'
import {
  getLanguagesStart,
  getLanguagesSuccess,
  getLanguagesFailure,
  getLanguageSupportKeysStart,
  getLanguageSupportKeysSuccess,
  getLanguageSupportKeysFailure,
  uploadLanguageCSVStart,
  uploadLanguageCSVComplete,
  updateLanguageSupportStart,
  updateLanguageSupportComplete,
  getCMSLanguagesStart,
  getCMSLanguagesSuccess,
  getCMSLanguagesFailure,
  getTALanguageStart,
  getTALanguageComplete,
  setTALanguageStart,
  setTALanguageComplete
} from '../redux-slices/languages'
import { toast } from '../../components/Toast'
import { serialize } from 'object-to-formdata'
import { SuperAdminRoutes, TenantRoutes } from '../../routes'
import { fetchTenantDetailsStart } from '../redux-slices/login'

export default function * languagesWatcher () {
  yield takeLatest(getLanguagesStart.type, getLanguagesWorker)
  yield takeLatest(getLanguageSupportKeysStart.type, getLanguageSupportKeysWorker)
  yield takeLatest(uploadLanguageCSVStart.type, uploadLanguageCSVWorker)
  yield takeLatest(updateLanguageSupportStart.type, updateLanguageSupportWorker)
  yield takeLatest(getCMSLanguagesStart.type, getCMSLanguagesWorker)
  yield takeLatest(getTALanguageStart.type, getTALanguageWorker)
  yield takeLatest(setTALanguageStart.type, setTALanguageWorker)
}

function * getLanguagesWorker (action) {
  try {
    const { limit, pageNo, name } = action && action.payload

    const { data } = yield getLanguages({ limit, pageNo, name })

    yield put(getLanguagesSuccess(data?.data?.languages))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0].description, 'error')

    yield put(getLanguagesFailure(e?.response?.data?.errors[0].description))
  }
}

function * getLanguageSupportKeysWorker (action) {
  try {
    const { tenantId, language, isTenant, errorCodes } = action && action.payload

    const { data } = yield getLanguageSupportKeys({ tenantId, language, isTenant, errorCodes })

    yield put(getLanguageSupportKeysSuccess(data?.data?.response || data?.data))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0].description, 'error')

    yield put(getLanguageSupportKeysFailure(e?.response?.data?.errors[0].description))
  }
}

function * uploadLanguageCSVWorker (action) {
  try {
    let { data, isTenant, tenantId, language, errorCodes } = action && action.payload
    data = serialize(data)

    yield uploadLanguageCSV({ data, isTenant })

    yield put(uploadLanguageCSVComplete())
    yield toast('File Uploaded Successfully', 'success')

    yield put(getLanguageSupportKeysStart({ isTenant, tenantId, language, errorCodes }))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0].description, 'error')

    yield put(uploadLanguageCSVComplete())
  }
}

function * updateLanguageSupportWorker (action) {
  try {
    const { data, isTenant, tenantId, navigate, errorCodes, state } = action && action.payload

    yield errorCodes ? updateErrorCodes(data?.type !== 'key' ? data : data?.data) : updateLanguageSupport({ data, isTenant })

    yield put(updateLanguageSupportComplete())
    data?.type === 'create'
      ? yield toast('Language Support Keys Created Successfully', 'success')
      : yield toast('Language Support Keys Updated Successfully', 'success')

    if (data?.type === 'key') {
      isTenant
        ? navigate(`/tenant/language-management/edit/${Object.keys(data?.data)?.[0]}`)
        : navigate(`/super-admin/${errorCodes ? 'err/' : ''}language-management/edit/${errorCodes ? data?.data?.key : Object.keys(data?.data)?.[0]}`, { state: { myAdminId: state?.myAdminId || '', myTenantId: state?.myTenantId || '' } })
    } else if (data?.type === 'create') {
      isTenant
        ? navigate(TenantRoutes.LanguageManagement)
        : navigate(errorCodes ? SuperAdminRoutes.ErrLanguageManagement : SuperAdminRoutes.LanguageManagement, { state: { myAdminId: state?.myAdminId || '', myTenantId: state?.myTenantId || '' } })
    }
    yield put(getLanguageSupportKeysStart({ isTenant, tenantId, language: '', errorCodes }))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0].description, 'error')

    yield put(updateLanguageSupportComplete())
  }
}

function * getCMSLanguagesWorker (action) {
  try {
    const { adminId = '', tenantId = '' } = action && action.payload

    const { data } = yield getCMSLanguage({
      adminId: adminId === null ? '' : adminId,
      tenantId: tenantId === null ? '' : tenantId
    })

    yield put(getCMSLanguagesSuccess(data?.data?.languages))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0].description, 'error')

    yield put(getCMSLanguagesFailure(e?.response?.data?.errors[0].description))
  }
}

function * getTALanguageWorker () {
  try {
    const { data } = yield getTALanguages()

    yield put(getTALanguageComplete(data?.data?.languages))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0].description, 'error')

    yield put(getTALanguageComplete(e?.response?.data?.errors[0].description))
  }
}

function * setTALanguageWorker (action) {
  try {
    const { data } = action && action.payload

    yield setTALanguages(data)

    yield put(fetchTenantDetailsStart())

    yield put(setTALanguageComplete())
  } catch (e) {
    yield toast(e?.response?.data?.errors[0].description, 'error')

    yield put(setTALanguageComplete(e?.response?.data?.errors[0].description))
  }
}
