import { Spinner } from '@themesberg/react-bootstrap'
import React from 'react'
import { Button } from 'react-bootstrap'
import CountryForm from '../../../CustomPayment/components/CountryForm'
import useBonusCountry from '../hooks/useBonusCountry'

const BonusCountry = ({
  data,
  setData,
  isTenant = false,
  details = false,
  values,
  setSelectedTab,
  handleSubmit,
  isEdit = false,
  bonusDetail
}) => {
  const { search, setSearch, myCountries, loading } = useBonusCountry(isTenant)
  return (
    <>
      <CountryForm
        data={data}
        setData={setData}
        search={search}
        setSearch={setSearch}
        myCountries={myCountries}
        isTenant={isTenant}
        loading={loading}
        details={details || values?.bonusType === 'balance'}
      />
      {!details &&
        <div
          className='mt-4 d-flex justify-content-between align-items-center'
        >
          <Button
            variant='outline-warning'
            onClick={() => setSelectedTab(
              (isEdit && bonusDetail?.claimedCount)
                ? bonusDetail?.bonusType === 'match' ? 'payment' : 'general'
                : ((values?.bonusType === 'freespins' || values?.bonusType === 'cashfreespins')
                    ? 'games'
                    : (values?.bonusType === 'balance' || values?.bonusType === 'promotion')
                        ? 'general'
                        : values?.bonusType === 'winBoost'
                          ? 'loyalty'
                          : 'wageringContribution')
            )}
          >
            Previous
          </Button>

          <div>
            <Button
              variant='outline-success'
              onClick={() => {
                handleSubmit()
              }}
            >
              Submit
              {loading && (
                <Spinner
                  as='span'
                  animation='border'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                />
              )}
            </Button>
          </div>
        </div>}
    </>
  )
}

export default BonusCountry
