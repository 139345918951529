import { takeLatest, put } from 'redux-saga/effects'

import {
  getTenantCasinoCategories,
  getTenantCasinoSubCategories,
  tenantViewToggleStatus,
  createTenantCasinoCategory,
  updateTenantCasinoCategory,
  createTenantCasinoSubCategory,
  updateTenantCasinoSubCategory,
  getTenantGames,
  getMasterGames,
  addGamesToSubCategory,
  deleteTenantCasinoGame,
  updateTenantCategoryGame,
  updateSubCategoryReOrder,
  updateCategoryReOrder,
  deleteTenantAdminCategory,
  deleteTenantAdminSubCategory,
  getAllTAProviders,
  getFreeSpinGames
} from '../../utils/apiCalls'

import {
  getAllCasinoCategoriesStart,
  getAllCasinoCategoriesSuccess,
  getAllCasinoCategoriesFailure,
  getAllSubCategoriesStart,
  getAllSubCategoriesSuccess,
  getAllSubCategoriesFailure,
  updateCategoryStatusStart,
  updateCategoryStatusComplete,
  createCasinoCategoryStart,
  createCasinoCategoryComplete,
  updateCasinoCategoryStart,
  updateCasinoCategoryComplete,
  updateSubCategoryStatusStart,
  updateSubCategoryStatusComplete,
  createSubCategoryStart,
  createSubCategoryComplete,
  updateSubCategoryStart,
  updateSubCategoryComplete,
  getAllGamesStart,
  getAllGamesSuccess,
  getAllGamesFailure,
  getAllMasterGamesStart,
  getAllMasterGamesSuccess,
  getAllMasterGamesFailure,
  addGamesToSubCategoryStart,
  addGamesToSubCategoryComplete,
  deleteTenantCasinoGameStart,
  deleteTenantCasinoGameComplete,
  updateTenantCasinoGameStatusStart,
  updateTenantCasinoGameStatusComplete,
  updateTenantCategoryGameStart,
  updateTenantCategoryGameComplete,
  updateCategoryReOrderStart,
  updateCategoryReOrderComplete,
  deleteCategoryStart,
  deleteCategoryComplete,
  deleteSubCategoryStart,
  deleteSubCategoryComplete,
  getAllTAProvidersStart,
  getAllTAProvidersSuccess,
  getAllTAProvidersFailure,
  getTAFreeSpinGamesSuccess,
  getTAFreeSpinGamesFailure,
  getTAFreeSpinGamesStart
} from '../redux-slices/tenantCasino'

import { objectToFormData } from '../../utils/objectToFormdata'
import { toast } from '../../components/Toast'
import { TenantRoutes } from '../../routes'
import { serialize } from 'object-to-formdata'

export default function * tenantCasinoWatcher () {
  yield takeLatest(
    getAllCasinoCategoriesStart.type,
    getAllCasinoCategoriesWorker
  )
  yield takeLatest(
    getAllSubCategoriesStart.type,
    getAllSubCategoriesWorker
  )

  yield takeLatest(
    updateCategoryStatusStart.type,
    updateCategoryStatusWorker
  )

  yield takeLatest(
    createCasinoCategoryStart.type,
    createCasinoCategoryWorker
  )

  yield takeLatest(
    updateCasinoCategoryStart.type,
    updateCasinoCategoryWorker
  )

  yield takeLatest(
    updateSubCategoryStatusStart.type,
    updateSubCategoryStatusWorker
  )

  yield takeLatest(
    createSubCategoryStart.type,
    createSubCategoryWorker
  )

  yield takeLatest(
    updateSubCategoryStart.type,
    updateSubCategoryWorker
  )

  yield takeLatest(
    getAllGamesStart.type,
    getAllGamesWorker
  )

  yield takeLatest(
    getAllMasterGamesStart.type,
    getAllMasterGamesWorker
  )

  yield takeLatest(
    addGamesToSubCategoryStart.type,
    addGamesToSubCategoryWorker
  )

  yield takeLatest(
    deleteTenantCasinoGameStart.type,
    deleteTenantCasinoGameWorker
  )

  yield takeLatest(
    updateTenantCasinoGameStatusStart.type,
    updateTenantCasinoGameStatusWorker
  )

  yield takeLatest(
    updateTenantCategoryGameStart.type,
    updateTenantCategoryGameWorker
  )

  yield takeLatest(
    updateCategoryReOrderStart.type,
    updateSubCategoryOrder
  )

  yield takeLatest(
    deleteCategoryStart.type,
    deleteCategoryWorker
  )

  yield takeLatest(
    deleteSubCategoryStart.type,
    deleteSubCategoryWorker
  )

  yield takeLatest(
    getAllTAProvidersStart.type,
    getAllTAProvidersWorker
  )

  yield takeLatest(getTAFreeSpinGamesStart.type, getTAFreeSpinGamesWorker)
}

function * getAllCasinoCategoriesWorker (action) {
  try {
    const { limit, pageNo } = action && action.payload

    const { data } = yield getTenantCasinoCategories({
      limit,
      pageNo
    })

    yield put(getAllCasinoCategoriesSuccess(data?.data?.casinoCategories))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAllCasinoCategoriesFailure())
  }
}

function * getAllSubCategoriesWorker (action) {
  try {
    const { limit, pageNo, categoryId, search, isActive, orderBy, sort } = action && action.payload

    const { data } = yield getTenantCasinoSubCategories({
      limit,
      pageNo,
      categoryId,
      search,
      isActive,
      orderBy,
      sort
    })

    yield put(getAllSubCategoriesSuccess(data?.data?.casinoSubCategories))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAllSubCategoriesFailure())
  }
}

function * createCasinoCategoryWorker (action) {
  try {
    const { data, limit, pageNo } = action && action.payload

    yield createTenantCasinoCategory(data)

    yield put(createCasinoCategoryComplete())

    yield put(getAllCasinoCategoriesStart({ limit, pageNo }))

    yield toast('Casino Category Created Successfully', 'success')
  } catch (e) {
    yield put(createCasinoCategoryComplete())

    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}

function * updateCasinoCategoryWorker (action) {
  try {
    const {
      data,
      limit,
      pageNo
    } = action && action.payload

    yield updateTenantCasinoCategory(data)

    yield put(updateCasinoCategoryComplete())

    yield put(getAllCasinoCategoriesStart({ limit, pageNo }))

    yield toast('Casino Category Updated Successfully', 'success')
  } catch (e) {
    yield put(updateCasinoCategoryComplete())

    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}

function * updateCategoryStatusWorker (action) {
  try {
    const { categoryId, status, code, limit, pageNo } =
      action && action.payload

    yield tenantViewToggleStatus({ tenantGameCategoryId: categoryId, status, code })

    yield put(updateCategoryStatusComplete())

    yield put(getAllCasinoCategoriesStart({ limit, pageNo }))

    yield toast('Casino Category Status Updated Successfully', 'success')
  } catch (e) {
    yield put(updateCategoryStatusComplete())

    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}

function * updateSubCategoryStatusWorker (action) {
  try {
    const { categoryId, status, code, limit, pageNo, search, categoryFilter, isActive } =
      action && action.payload

    yield tenantViewToggleStatus({ tenantGameSubCategoryId: categoryId, status, code })

    yield put(updateSubCategoryStatusComplete())

    yield put(getAllSubCategoriesStart({ limit, pageNo, search, categoryId: categoryFilter, isActive, orderBy: 'tenantGameSubCategoryId', sort: 'desc' }))

    yield toast('Casino Sub Category Status Updated Successfully', 'success')
  } catch (e) {
    yield put(updateSubCategoryStatusComplete())

    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}

function * createSubCategoryWorker (action) {
  try {
    const { data, limit, pageNo, search, isActive, categoryFilter } = action && action.payload

    yield createTenantCasinoSubCategory(serialize(data))

    yield put(createSubCategoryComplete())

    yield put(getAllSubCategoriesStart({ limit, pageNo, search, categoryId: categoryFilter, isActive, orderBy: 'tenantGameSubCategoryId', sort: 'desc' }))

    yield toast('Casino Sub Category Created Successfully', 'success')
  } catch (e) {
    yield put(createSubCategoryComplete())

    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}

function * updateSubCategoryWorker (action) {
  try {
    const { data, limit, pageNo, search, categoryFilter, isActive } = action && action.payload

    yield updateTenantCasinoSubCategory(serialize(data))

    yield put(updateSubCategoryComplete())

    yield put(getAllSubCategoriesStart({ limit, pageNo, search, categoryId: categoryFilter, isActive, orderBy: 'tenantGameSubCategoryId', sort: 'desc' }))

    yield toast('Casino Sub Category Updated Successfully', 'success')
  } catch (e) {
    yield put(updateSubCategoryComplete())

    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}

function * updateSubCategoryOrder (action) {
  try {
    const { data, navigate, isSubCategory } = action && action.payload
    isSubCategory ? yield updateSubCategoryReOrder(data) : updateCategoryReOrder(data)
    yield put(updateCategoryReOrderComplete())
    yield toast(`Casino${isSubCategory ? ' Sub' : ''} Category Updated Successfully`, 'success')
    navigate(isSubCategory ? '/tenant/casino-sub-category' : '/tenant/casino-category')
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}

function * getAllGamesWorker (action) {
  try {
    const { limit, pageNo, casinoCategoryId, isActive, orderBy, sort, providerId, search } = action && action.payload

    const { data } = yield getTenantGames({
      limit,
      pageNo,
      casinoCategoryId,
      isActive,
      orderBy,
      sort,
      providerId,
      search: search || ''
    })

    yield put(getAllGamesSuccess(data?.data?.categoryGames))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAllGamesFailure())
  }
}

function * getAllMasterGamesWorker (action) {
  try {
    const { bonusId, limit, pageNo, search, casinoCategoryId, providerId, freespins, countryCode = '', tenantIds = '[]' } = action && action.payload

    const { data } = yield getMasterGames({
      limit,
      pageNo,
      search,
      casinoCategoryId,
      providerId,
      freespins: freespins || '',
      bonusId: bonusId || '',
      countryCode: countryCode || '',
      tenantIds
    })

    yield put(getAllMasterGamesSuccess(data?.data?.casinoGames))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAllMasterGamesFailure())
  }
}

function * addGamesToSubCategoryWorker (action) {
  try {
    const { tenantGameSubCategoryId, games, navigate } = action && action.payload

    yield addGamesToSubCategory({
      tenantGameSubCategoryId: parseInt(tenantGameSubCategoryId),
      games
    })

    yield toast('Games Added To Sub Category', 'success')

    navigate(TenantRoutes.CasinoSubCategory)

    yield put((addGamesToSubCategoryComplete()))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put((addGamesToSubCategoryComplete()))
  }
}

function * deleteTenantCasinoGameWorker (action) {
  try {
    const {
      data,
      limit,
      pageNo,
      casinoCategoryId,
      isActive,
      providerId
    } = action && action.payload

    yield deleteTenantCasinoGame(data)

    yield put(getAllGamesStart({
      limit,
      pageNo,
      casinoCategoryId,
      isActive,
      orderBy: 'categoryGameId',
      sort: 'desc',
      providerId
    }))

    yield put(deleteTenantCasinoGameComplete())

    yield toast('Game Deleted Successfully', 'success')
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(deleteTenantCasinoGameComplete())
  }
}

function * updateTenantCasinoGameStatusWorker (action) {
  try {
    const {
      data,
      limit,
      pageNo,
      casinoCategoryId,
      isActive,
      providerId
    } =
      action && action.payload

    yield tenantViewToggleStatus(data)

    yield put(getAllGamesStart({
      limit,
      pageNo,
      casinoCategoryId,
      isActive,
      orderBy: 'categoryGameId',
      sort: 'desc',
      providerId
    }))

    yield put(updateTenantCasinoGameStatusComplete())

    yield toast('Casino Game Status Updated Successfully', 'success')
  } catch (e) {
    yield put(updateTenantCasinoGameStatusComplete())

    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}

function * updateTenantCategoryGameWorker (action) {
  try {
    const {
      data,
      limit,
      pageNo,
      casinoCategoryId,
      isActive,
      providerId
    } =
      action && action.payload

    yield updateTenantCategoryGame(objectToFormData(data))

    yield put(getAllGamesStart({
      limit,
      pageNo,
      casinoCategoryId,
      isActive,
      orderBy: 'categoryGameId',
      sort: 'desc',
      providerId
    }))

    yield put(updateTenantCategoryGameComplete())

    yield toast('Casino Game Updated Successfully', 'success')
  } catch (e) {
    yield put(updateTenantCategoryGameComplete())

    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}

function * deleteCategoryWorker (action) {
  try {
    const { data, limit, pageNo } = action && action.payload

    yield deleteTenantAdminCategory(data)

    yield put(getAllCasinoCategoriesStart({
      limit,
      pageNo
    }))

    yield put(deleteCategoryComplete())

    yield toast('Casino Category Deleted Successfully', 'success')
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(deleteCategoryComplete())
  }
}

function * deleteSubCategoryWorker (action) {
  try {
    const { data, limit, pageNo, search, categoryFilter, isActive } = action && action.payload

    yield deleteTenantAdminSubCategory(data)

    yield put(getAllSubCategoriesStart({ limit, pageNo, search, categoryId: categoryFilter, isActive, orderBy: 'tenantGameSubCategoryId', sort: 'desc' }))

    yield put(deleteSubCategoryComplete())

    yield toast('Casino Sub Category Deleted Successfully', 'success')
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(deleteSubCategoryComplete())
  }
}

function * getAllTAProvidersWorker () {
  try {
    const { data } = yield getAllTAProviders()

    yield put(getAllTAProvidersSuccess(data?.data?.providerList))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAllTAProvidersFailure())
  }
}

function * getTAFreeSpinGamesWorker (action) {
  try {
    const { limit, pageNo, providerId, search, bonusId, tenantIds = '[]' } = action && action.payload

    const { data } = yield getFreeSpinGames({
      limit, pageNo, providerId, search, bonusId, isTenant: true, tenantIds
    })

    yield put(getTAFreeSpinGamesSuccess(data?.data?.gameWithFreespin))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getTAFreeSpinGamesFailure(e?.response?.data?.errors[0]?.description))
  }
}
