import { useLocation } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import Credentials from './components/TenantCredentials'
import AffiliateSystem from './components/AffiliateSystem'
import { Row, Col, Card, Tabs, Tab } from '@themesberg/react-bootstrap'

const TenantCredentials = () => {
  const { state } = useLocation()
  const [selectedTab, setSelectedTab] = useState(state || 'affiliate')

  useEffect(() => {
    window.history.replaceState({}, '')
  }, [])

  return (
    <>
      <Row>
        <Col>
          <h3>Tenant Credentials</h3>
        </Col>
      </Row>

      <Card body>

        <Tabs activeKey={selectedTab} className='nav-light' onSelect={key => setSelectedTab(key)}>
          <Tab eventKey='affiliate' title='Affiliate System'>
            <div className='mt-5'>
              <AffiliateSystem />
            </div>
          </Tab>

          <Tab eventKey='credentials' title='Credentials'>
            <div className='mt-5'>
              <Credentials />
            </div>
          </Tab>

        </Tabs>
      </Card>
    </>
  )
}

export default TenantCredentials
