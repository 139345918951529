import { takeLatest, put } from 'redux-saga/effects'
import {
  getTenantTransactionUsersStart,
  getTenantTransactionUsersSuccess,
  getTenantTransactionUsersFailure,
  getAllTenantCasinoTransactionsStart,
  getAllTenantCasinoTransactionsSuccess,
  getAllTenantCasinoTransactionsFailure,
  getAllTenantTransactionsStart,
  getAllTenantTransactionsSuccess,
  getAllTenantTransactionsFailure

} from '../redux-slices/tenantTransactions'
import {
  getAllTenantCasinoTransactions,
  getAllTenantTransactions, getTenantTransactionUsers
} from '../../utils/apiCalls'
import { toast } from '../../components/Toast'

export default function* tenantTransactionsWatcher() {
  yield takeLatest(getAllTenantTransactionsStart.type, getAllTenantTransactionsWorker)
  yield takeLatest(getTenantTransactionUsersStart.type, getTenantTransactionUsersWorker)
  yield takeLatest(getAllTenantCasinoTransactionsStart.type, getAllTenantCasinoTransactionsWorker)
}

function* getAllTenantTransactionsWorker(action) {
  try {
    const {
      countryCode, limit, pageNo, search, startDate, endDate, currencyId,
      transactionType, paymentProvider, isUserDetail, userId, status,
      transactionId, createdAtStartDate, createdAtEndDate, paymentMethod, check, orderBy = '', sort = ''
    } =
      action && action.payload

    const { data } = yield getAllTenantTransactions({
      limit,
      pageNo,
      search,
      startDate,
      endDate,
      currencyId,
      transactionType,
      paymentProvider,
      isUserDetail,
      userId,
      status,
      countryCode,
      transactionId,
      createdAtStartDate,
      createdAtEndDate,
      paymentMethod,
      check,
      orderBy,
      sort
    })

    yield put(getAllTenantTransactionsSuccess(data?.data?.transactionDetail))
  } catch (e) {
    yield put(getAllTenantTransactionsFailure())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function* getTenantTransactionUsersWorker(action) {
  try {
    const { type, email } = action && action.payload

    const { data } = yield getTenantTransactionUsers({ type, email })

    yield put(getTenantTransactionUsersSuccess(data?.data?.transactionUsers))
  } catch (e) {
    yield put(getTenantTransactionUsersFailure())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function* getAllTenantCasinoTransactionsWorker(action) {
  try {
    const { limit, pageNo, startDate, endDate, currencyCode, status, transactionType, userId, orderBy, orderType, searchByUserId = '', amountType = '', gameSearch = '', providers = '', showTotalAmount = false } =
      action && action.payload
    const { data } = yield getAllTenantCasinoTransactions(({
      limit,
      pageNo,
      startDate,
      endDate,
      currencyCode,
      searchByUserId,
      status,
      transactionType,
      userId,
      orderBy,
      orderType,
      amountType,
      gameSearch,
      providers,
      showTotalAmount
    }))

    yield put(getAllTenantCasinoTransactionsSuccess(data?.data?.transactionDetail))
  } catch (e) {
    yield put(getAllTenantCasinoTransactionsFailure())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}
