import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getLanguageSupportKeysStart, updateLanguageSupportStart } from '../../../store/redux-slices/languages'
import { getItem } from '../../../utils/storageUtils'
import { keysNotToBeShown } from './constant'

const useAddLanguage = ({ isTenant, errorCodes = false }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { state } = useLocation()
  const [selectedClient, setSelectedClient] = useState(state?.myAdminId || '')
  const [selectedPortal, setSelectedPortal] = useState(state?.myTenantId || '')
  const tenantId = getItem('tenant-id')
  const { lang } = useParams()
  const { languageKeys, languageTabs } = useSelector(state => state.languages)
  const [myKeys, setMyKeys] = useState({})
  const [keys, setKeys] = useState({})
  const [isError, setIsError] = useState({})
  const [selectedKeyTab, setSelectedKeyTab] = useState('')

  useEffect(() => {
    dispatch(getLanguageSupportKeysStart({ isTenant, tenantId: isTenant ? tenantId : selectedPortal || '', language: '', errorCodes }))
  }, [errorCodes, selectedPortal])

  useEffect(() => {
    if (languageKeys && languageKeys?.length) {
      const data = {}
      const data2 = {}
      if (errorCodes) {
        for (const key in languageKeys) {
          const keyData = {}
          const myKey = languageKeys[key].key
          const value = typeof languageKeys[key].value === 'string' ? JSON.parse(languageKeys[key].value) : {}
          data[myKey] = ''
          for (const language in value) {
            if (language !== 'PIQ') {
              keyData[language] = value[language]
            }
          }
          data2[myKey] = keyData
        }
        setKeys(data2)
      } else {
        for (const key in languageKeys[0]) {
          if (!keysNotToBeShown.includes(key)) data[key] = ''
        }
      }
      setMyKeys(data)
    }
  }, [languageKeys])

  useEffect(() => {
    if (languageTabs && Object.keys(languageTabs)?.length) {
      setSelectedKeyTab(Object.keys(languageTabs)?.[0])
    }
  }, [languageTabs])

  const updateData = (data) =>
    dispatch(updateLanguageSupportStart({ data, isTenant, tenantId: isTenant ? tenantId : selectedPortal || '', navigate, errorCodes, state: { myAdminId: selectedClient || '', myTenantId: selectedPortal || '' } }))

  const [keysData, setKeysData] = useState({})
  useEffect(() => {
    if (errorCodes && Object.keys(keys).length) {
      const keyData = {}
      Object.keys(keys).forEach((key) => {
        keyData[key] = keys[key].EN
      })
      setKeysData(keyData)
    }
  }, [keys, errorCodes])

  return {
    selectedKeyTab,
    navigate,
    languageTabs,
    setSelectedKeyTab,
    myKeys,
    languageKeyObj: errorCodes ? keysData : languageKeys?.find(obj => obj.language === 'EN'),
    lang,
    updateData,
    isError,
    setIsError,
    keys,
    selectedClient,
    setSelectedClient,
    selectedPortal,
    setSelectedPortal
  }
}

export default useAddLanguage
