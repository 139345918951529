import React from 'react'
import Preloader from '../../../../components/Preloader'
import useEditBannedGames from '../hooks/useEditBannedGames'
import BannedGamesDetailsListing from './BannedGamesDetailsListing'
import { Col, Row, Form as BForm, Button, Spinner } from '@themesberg/react-bootstrap'
import { toast } from '../../../../components/Toast'
import { setBannedGamesName } from '../../../../store/redux-slices/superAdminTransactions'
import ProvidersFilter from '../../../../components/ProviderFilter'

const EditBannedGames = () => {
  const {
    loading,
    navigate,
    page,
    limit,
    setLimit,
    setPage,
    getBannedGamesListDetail,
    totalBannedGamesPages,
    bannedGamesListId,
    isTenant,
    isClone,
    listBannedGames,
    setListBannedGames,
    listName,
    dispatch,
    handleSubmit,
    selectedProvider,
    setSelectedProvider,
    search,
    setSearch
  } = useEditBannedGames()

  return (
    <>
      <Row>
        <Col>
          <h3>{isClone ? 'Clone' : 'Edit'} Banned Games: {loading ? '' : getBannedGamesListDetail?.bannedGamesListName || ''}</h3>
        </Col>

        <Col xs='auto'>
          <Button
            className='banned-games-save-button'
            size='sm'
            variant='outline-success'
            onClick={() => {
              if (!listName) {
                return toast('Please Enter Name', 'error')
              }
              navigate(`/${isTenant ? 'tenant' : 'super-admin'}/banned-games/${isClone ? 'clone' : 'edit'}/add-games/${bannedGamesListId}`)
            }}
          >
            ADD GAMES
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs='auto'>
          <BForm.Label>
            Banned Games List Name <span className='text-danger'>*</span>
          </BForm.Label>

          <BForm.Control
            type='text'
            name='name'
            size='sm'
            style={{ maxWidth: '400px' }}
            placeholder='Enter Name'
            value={listName}
            onChange={(e) => dispatch(setBannedGamesName(e.target.value))}
          />
        </Col>

        <Col xs='auto' className='align-items-center provider'>
          <ProvidersFilter
            selectedProvider={selectedProvider}
            setSelectedProvider={setSelectedProvider}
            isTenantRoute={isTenant}
          />
        </Col>

        <Col xs='auto'>
          <BForm.Label>
            Search
          </BForm.Label>
          <BForm.Control
            type='search'
            placeholder='Search Banned Games'
            size='sm'
            style={{ maxWidth: '230px', marginRight: '10px', maxHeight: '15px' }}
            value={search}
            onChange={(event) =>
              setSearch(
                event.target.value.replace(/[~`!$%^&*#=)()><?]+/g, '')
              )}
          />
        </Col>
      </Row>

      <BannedGamesDetailsListing
        pageNo={page}
        setPageNo={setPage}
        totalPages={totalBannedGamesPages}
        limit={limit}
        setLimit={setLimit}
        getBannedGamesListDetail={getBannedGamesListDetail.gameDetail}
        actionButton
        bannedGamesListId={bannedGamesListId}
        isTenant={isTenant}
        isClone={isClone}
        listBannedGames={listBannedGames}
        setListBannedGames={setListBannedGames}
        selectedProvider={selectedProvider}
      />
      <div
        className='mt-4 d-flex justify-content-between align-items-center'
      >
        <Button
          variant='outline-warning'
          onClick={() => navigate(-1)}
        >
          Cancel
        </Button>

        <div>
          <Button
            variant='outline-success'
            onClick={() => { handleSubmit() }}
          >
            Submit
            {loading && (
              <Spinner
                as='span'
                animation='border'
                size='sm'
                role='status'
                aria-hidden='true'
              />
            )}
          </Button>
        </div>
      </div>
    </>
  )
}

export default EditBannedGames
