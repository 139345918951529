import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getTABonusStart } from '../../../../../store/redux-slices/bonus'
import { getWageringTemplateDetailStart } from '../../../../../store/redux-slices/wageringTemplate'

const useEditBonus = (limit, pageNo) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { bonusId } = useParams()
  const [selectedTab, setSelectedTab] = useState('general')
  const [set, setSet] = useState(false)
  const { loading, bonusDetail } = useSelector((state) => state.bonus)
  const [portalOptions, setPortalOptions] = useState([])
  const { tenantsList } = useSelector((state) => state.tenants)
  const [gameIds, setGameIds] = useState(bonusDetail?.gameIds || [])
  const [countryData, setCountryData] = useState([])
  const [state, setState] = useState([
    {
      startDate: bonusDetail ? new Date(bonusDetail?.validFrom) : new Date(),
      endDate: bonusDetail ? new Date(bonusDetail?.validTo) : new Date(),
      key: 'selection'
    }
  ])
  const [data, setData] = useState({
    promoTitle: bonusDetail?.promotionTitle || { EN: '' },
    desc: bonusDetail?.description || { EN: '' },
    terms: bonusDetail?.termCondition || { EN: '' }
  })

  useEffect(() => {
    if (bonusDetail) {
      setCountryData(bonusDetail?.other?.countries)
      setData({
        promoTitle: bonusDetail?.promotionTitle,
        desc: bonusDetail?.description,
        terms: bonusDetail?.termCondition
      })
      setSet(true)
    }
  }, [bonusDetail])

  useEffect(() => {
    if (bonusId) {
      dispatch(getTABonusStart({ bonusId, userBonusId: '' }))
    }
  }, [bonusId])

  useEffect(() => {
    if (bonusDetail) {
      if (bonusDetail?.wageringTemplateId) {
        dispatch(getWageringTemplateDetailStart({ wageringTemplateId: bonusDetail.wageringTemplateId, isTenant: true, limit, pageNo, providerId: '' }))
      }
      setState(([
        {
          startDate: bonusDetail ? new Date(bonusDetail?.validFrom) : new Date(),
          endDate: bonusDetail ? new Date(bonusDetail?.validTo) : new Date(),
          key: 'selection'
        }
      ]))
    }
    setGameIds(bonusDetail?.gameIds)
  }, [bonusDetail])

  useEffect(() => {
    if (tenantsList) {
      const options = tenantsList.rows.map((portal) => { return ({ label: portal.name, value: portal.tenantId }) })
      setPortalOptions(options)
    }
  }, [tenantsList])

  return {
    selectedTab,
    setSelectedTab,
    portalOptions,
    state,
    setState,
    dispatch,
    bonusDetail,
    loading,
    bonusId,
    navigate,
    data,
    setData,
    set,
    setGameIds,
    gameIds,
    countryData,
    setCountryData
  }
}

export default useEditBonus
