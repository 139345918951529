import React from 'react'
import LanguageMgmt from '../../../components/LanguageMgmt'

const LanguageManagement = () => {
  return (
    <>
      <LanguageMgmt isTenant />
    </>
  )
}

export default LanguageManagement
