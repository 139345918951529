import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getItem, getLoginToken } from '../../../../utils/storageUtils'
import useDidMountEffect from '../../../../utils/useDidMountEffect'
import { getGameReportStart } from '../../../../store/redux-slices/dashboard'
import { formatDateYMD, getDateDaysAgo } from '../../../../utils/dateFormatter'
import { getGameReportSortedValue, isTenant } from '../../../../utils/constants'

const useTopPlayers = ({ selectedPortal = '', selectedClient = '', accordionState }) => {
  const dispatch = useDispatch()
  const { gameReportLoading: loading, gameReport } = useSelector((state) => state.dashboard)
  const [gameReportData, setGameReportData] = useState(gameReport)
  const [selectedTab, setSelectedTab] = useState('game')
  const [dateOptions, setDateOptions] = useState('today')
  const [limit, setLimit] = useState(10)
  const isInitialRender = useDidMountEffect()
  const [sortBy, setSortBy] = useState({
    isSortingField: null,
    id: '',
    noOfRounds: '',
    noOfPlayer: '',
    totalBets: '',
    totalWins: '',
    gameRevenue: '',
    payout: ''
  })
  const [hover, setHover] = useState({
    id: false,
    noOfRounds: false,
    noOfPlayer: false,
    totalBets: false,
    totalWins: false,
    gameRevenue: false,
    payout: false
  })

  const [state, setState] = useState([
    {
      startDate: getDateDaysAgo(0),
      endDate: new Date(),
      key: 'selection'
    }
  ])

  const fetchDetails = () => {
    accordionState.gameReport && dispatch(getGameReportStart({
      isTenant,
      startDate: formatDateYMD(state.map(a => a.startDate)),
      endDate: formatDateYMD(state.map(a => a.endDate)),
      tenantId: selectedPortal,
      adminId: selectedClient,
      dateOptions,
      selectedTab
    }))
  }

  useEffect(() => {
    fetchDetails()
  }, [state, selectedClient, selectedPortal, selectedTab, accordionState.gameReport])

  useEffect(() => {
    const orderBy = getGameReportSortedValue[sortBy.isSortingField] || ''
    const sort = sortBy.id || sortBy.noOfPlayer || sortBy.noOfRounds || sortBy.totalBets || sortBy.totalWins || sortBy.gameRevenue || sortBy.payout

    if (gameReport && orderBy && sort) {
      const tableData = JSON.parse(JSON.stringify(gameReport))
      const dynamicSort = (property) => (a, b) => {
        if (sort === 'desc') {
          return parseFloat(b[property]) - parseFloat(a[property])
        } else {
          return parseFloat(a[property]) - parseFloat(b[property])
        }
      }
      const sortedTableData = tableData.sort(dynamicSort(orderBy))
      setGameReportData(sortedTableData)
    } else setGameReportData(gameReport)
  }, [sortBy, gameReport])

  useEffect(() => {
    if (dateOptions !== 'custom' && !isInitialRender) fetchDetails()
  }, [dateOptions])

  const getCsvDownloadUrl = () => {
    return `${process.env.REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/report/game?${!isTenant ? `adminId=${selectedClient}&` : ''}dateOptions=${dateOptions}&tenantId=${isTenant ? getItem('tenant-id') : selectedPortal}&customStartDate=${formatDateYMD(state.map(a => a.startDate))}&customEndDate=${formatDateYMD(state.map(a => a.endDate))}&tab=${selectedTab}&csvDownload=true&token=${getLoginToken()}`
  }

  return {
    state,
    limit,
    hover,
    sortBy,
    loading,
    setLimit,
    setSortBy,
    setHover,
    setState,
    gameReport,
    dateOptions,
    selectedTab,
    fetchDetails,
    gameReportData,
    setSelectedTab,
    setDateOptions,
    getCsvDownloadUrl
  }
}

export default useTopPlayers
