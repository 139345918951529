import React from 'react'
import {
  Row,
  Col,
  Table,
  Form,
  Button,
  ButtonGroup
} from '@themesberg/react-bootstrap'
import PaginationComponent from '../../../components/Pagination'
import {
  faArrowCircleDown,
  faArrowCircleUp,
  faCheckSquare,
  faEdit,
  faTrash,
  faWindowClose
} from '@fortawesome/pro-solid-svg-icons'

import Trigger from '../../../components/OverlayTrigger'
import useCasinoGamesListing from './hooks/useCasinoGamesListing'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ConfirmationModal, { DeleteConfirmationModal } from '../../../components/ConfirmationModal'
import EditGames from './components/EditGames'
import { allowedKeysforOrder, tableHeaders } from './constants'
import ProviderFilter from '../../../components/ProviderFilter'
import useCheckPermission from '../../../utils/checkPermission'
import { TenantRoutes } from '../../../routes'
import GameIdsModal from '../../Super-Admin/CasinoGames/components/GameIdsModal'

const CasinoGames = () => {
  const {
    limit,
    page,
    loading,
    setLimit,
    setPage,
    totalPages,
    games,
    casinoCategoryId,
    search,
    setSearch,
    setCasinoCategoryId,
    subCategories,
    show,
    setShow,
    handleShow,
    handleYes,
    active,
    handleShowModal,
    showModal,
    type,
    handleClose,
    gameData,
    categoryGameId,
    setDeleteModalShow,
    deleteModalShow,
    handleDeleteYes,
    handleDeleteModal,
    statusFilter,
    setStatusFilter,
    setOrderBy,
    setSort,
    setOver,
    selected,
    sort,
    over,
    selectedProvider,
    setSelectedProvider,
    getProviderName,
    navigate,
    name,
    showGameIds,
    setShowGameIds
  } = useCasinoGamesListing()
  const { isHidden } = useCheckPermission()

  return (
    <>
      <Row>
        <Col xs='auto'>
          <h3>Casino Games</h3>
        </Col>

        <Col xs='auto'>
          <div className='d-flex justify-content-end align-items-center'>
            <ProviderFilter
              selectedProvider={selectedProvider}
              setSelectedProvider={setSelectedProvider}
              isTenantRoute
            />

            <Form.Label column='sm' style={{ marginBottom: '0', marginRight: '15px', minWidth: 'fit-content' }}>
              Sub Category
            </Form.Label>

            <Form.Select
              style={{ marginBottom: '0', marginRight: '15px', maxWidth: '230px' }}
              value={casinoCategoryId}
              size='sm'
              onChange={(e) => setCasinoCategoryId(e.target.value)}
            >
              <option value=''>All</option>

              {subCategories && subCategories?.rows?.map((c) => (
                <option key={c?.tenantGameSubCategoryId} value={c?.tenantGameSubCategoryId}>{c?.name?.EN}</option>
              ))}
            </Form.Select>

            <Form.Label column='sm' style={{ marginBottom: '0', marginRight: '15px' }}>
              Status
            </Form.Label>

            <Form.Select
              onChange={(e) => { setStatusFilter(e.target.value) }}
              value={statusFilter}
              size='sm'
              style={{ maxWidth: '230px', marginRight: '15px' }}
            >
              <option value=''>All</option>
              <option value='true'>Active</option>
              <option value='false'>In-Active</option>
            </Form.Select>

            <Form.Label column='sm' style={{ marginBottom: '0', marginRight: '15px' }}>
              Search
            </Form.Label>&nbsp;
            <Form.Control
              name='search'
              type='search'
              placeholder='Search'
              value={search}
              size='sm'
              className='w-90 mx-auto'
              style={{ minWidth: '180px', maxWidth: '230px' }}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
            />

            <Button
              variant='outline-success'
              size='sm'
              className='ml-1'
              style={{ marginLeft: '15px' }}
              onClick={() => navigate(TenantRoutes.GameReorder)}
            >
              Reorder
            </Button>

            <Button
              variant='outline-success'
              size='sm'
              className='ml-1'
              style={{ marginLeft: '15px', minWidth: '120px', maxWidth: '230px' }}
              onClick={() => setShowGameIds(true)}
            >
              Get Game IDS
            </Button>

          </div>
        </Col>
      </Row>

      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            {tableHeaders.map((h, idx) => (
              <th
                key={idx}
                onClick={() => allowedKeysforOrder.includes(h.value) ? setOrderBy(h.value) : setOrderBy(allowedKeysforOrder[0])}
                style={{
                  cursor: 'pointer'
                }}
                className={
                            selected(h)
                              ? `${h.value === 'returnToPlayer' ? 'text-left' : ''}`
                              : `${h.value === 'returnToPlayer' ? 'text-left' : ''}`
                          }
              >
                {h.label} &nbsp;
                {selected(h) &&
                            (sort === 'asc'
                              ? (
                                <FontAwesomeIcon
                                  style={over ? { color: 'red' } : {}}
                                  icon={faArrowCircleUp}
                                  onClick={() => setSort('desc')}
                                  onMouseOver={() => setOver(true)}
                                  onMouseLeave={() => setOver(false)}
                                />
                                )
                              : (
                                <FontAwesomeIcon
                                  style={over ? { color: 'red' } : {}}
                                  icon={faArrowCircleDown}
                                  onClick={() => setSort('asc')}
                                  onMouseOver={() => setOver(true)}
                                  onMouseLeave={() => setOver(false)}
                                />
                                ))}
              </th>
            ))}
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {!loading && games?.count > 0 &&
                    games?.rows?.map(
                      ({
                        name,
                        isActive,
                        categoryGameId,
                        thumbnailUrl,
                        tenantGameSubCategoryId,
                        returnToPlayer,
                        TenantGameSubCategory: {
                          name: subCategoryName,
                          isActive: subCategoryActive,
                          TenantGameCategory: {
                            name: categoryName,
                            isActive: categoryActive
                          }
                        },
                        MasterCasinoGame,
                        masterCasinoProviderId,
                        masterCasinoGameId
                      }, index) => {
                        return (
                          <tr key={categoryGameId}>
                            <td>{categoryGameId}</td>
                            <td>
                              {name}
                            </td>

                            <td>
                              <span
                                onClick={() => window.open(thumbnailUrl)}
                                className='text-link'
                                style={{ cursor: 'pointer' }}
                              >
                                View
                              </span>
                            </td>

                            <td className='text-left'>{returnToPlayer}</td>

                            <td className='text-capitalize'>{MasterCasinoGame?.devices?.length ? MasterCasinoGame.devices?.map(type => type).join(', ') : 'None'}</td>

                            <td>
                              {isActive
                                ? (
                                  <span className='text-success'>Active</span>
                                  )
                                : (
                                  <span className='text-danger'>In Active</span>
                                  )}
                            </td>

                            <td className='text-capitalize'>{getProviderName(masterCasinoProviderId)}</td>

                            <td>{masterCasinoGameId}</td>

                            <td>
                              {subCategoryName?.EN}
                              {'  ( '}
                              {subCategoryActive
                                ? <span className='text-success'>Active</span>
                                : <span className='text-danger'>In Active</span>}
                              {' )'}
                            </td>

                            <td>
                              {categoryName?.EN}
                              {'  ( '}
                              {categoryActive
                                ? <span className='text-success'>Active</span>
                                : <span className='text-danger'>In Active</span>}
                              {' )'}
                            </td>

                            <td>
                              <ButtonGroup>
                                <Trigger message='Edit'>
                                  <Button
                                    className='m-1'
                                    size='sm'
                                    variant='warning'
                                    hidden={isHidden({ module: { key: 'CasinoManagement', value: 'U' } })}
                                    onClick={() => {
                                      handleShowModal('Edit', games?.rows[index], categoryGameId)
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faEdit} />
                                  </Button>
                                </Trigger>

                                {!isActive
                                  ? (
                                    <Trigger message='Set Game Status Active'>
                                      <Button
                                        className='m-1'
                                        size='sm'
                                        variant='success'
                                        hidden={isHidden({ module: { key: 'CasinoManagement', value: 'T' } })}
                                        onClick={() =>
                                          handleShow(categoryGameId, tenantGameSubCategoryId, isActive, name)}
                                      >
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </Button>
                                    </Trigger>
                                    )
                                  : (
                                    <Trigger message='Set Game Status In-Active'>
                                      <Button
                                        className='m-1'
                                        size='sm'
                                        variant='danger'
                                        hidden={isHidden({ module: { key: 'CasinoManagement', value: 'T' } })}
                                        onClick={() =>
                                          handleShow(categoryGameId, tenantGameSubCategoryId, isActive, name)}
                                      >
                                        <FontAwesomeIcon icon={faWindowClose} />
                                      </Button>
                                    </Trigger>
                                    )}

                                <Trigger message='Delete'>
                                  <Button
                                    className='m-1'
                                    size='sm'
                                    variant='danger'
                                    hidden={isHidden({ module: { key: 'CasinoManagement', value: 'D' } })}
                                    onClick={() => handleDeleteModal(categoryGameId, name)}
                                  >
                                    <FontAwesomeIcon icon={faTrash} />
                                  </Button>
                                </Trigger>
                              </ButtonGroup>
                            </td>
                          </tr>
                        )
                      }
                    )}

          {games?.count === 0 && !loading &&
                      (
                        <tr>
                          <td
                            colSpan={8}
                            className='text-danger text-center'
                          >
                            No data found
                          </td>
                        </tr>
                      )}
        </tbody>
      </Table>

      {games?.count !== 0 && !loading &&
            (
              <PaginationComponent
                page={games?.count < page ? setPage(1) : page}
                totalPages={totalPages}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
              />
            )}

      {show && (
        <ConfirmationModal
          setShow={setShow}
          show={show}
          handleYes={handleYes}
          active={active}
          name={name}
        />
      )}

      {deleteModalShow &&
        (
          <DeleteConfirmationModal
            deleteModalShow={deleteModalShow}
            setDeleteModalShow={setDeleteModalShow}
            handleDeleteYes={handleDeleteYes}
            name={name}
          />)}

      {categoryGameId !== null && gameData?.categoryGameId === categoryGameId &&
        <EditGames
          handleClose={handleClose}
          show={showModal}
          gameData={gameData}
          type={type}
          subCategories={subCategories}
          limit={limit}
          pageNo={page}
          casinoCategoryId={casinoCategoryId}
          statusFilter={statusFilter}
          providerId={selectedProvider}
        />}

      {showGameIds &&
        <GameIdsModal
          setShow={setShowGameIds}
          show={showGameIds}
          isTenant
        />}
    </>

  )
}

export default CasinoGames
