import axios from 'axios'
import { serialize } from 'object-to-formdata'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getItem, getLoginToken } from '../../utils/storageUtils'
import { deleteFromGalleryStart, getImageGalleryStart, getImageGallerySuccess } from '../../store/redux-slices/emailTemplate'
import { toast } from '../../components/Toast'
import useCheckPermission from '../../utils/checkPermission'

const useUploadGallery = ({ isTenant }) => {
  const dispatch = useDispatch()
  const { isHidden } = useCheckPermission()
  const { REACT_APP_API_URL } = process.env
  const tenantId = parseInt(getItem('tenant-id'))
  const [imageDelete, setImageDelete] = useState()
  const [initialState, setInitialState] = useState([])
  const [selectedClient, setSelectedClient] = useState('')
  const [selectedPortal, setSelectedPortal] = useState('')
  const [selectedTab, setSelectedTab] = useState('common')
  const [deleteModalShow, setDeleteModalShow] = useState(false)
  const { gallery } = useSelector(state => state.emailTemplate)
  const cdnHeader = process.env.REACT_APP_CDN_IMAGE_HEADER
  const s3Header = process.env.REACT_APP_S3_IMAGE_HEADER

  useEffect(() => {
    (selectedClient ? selectedPortal : true) &&
    dispatch(getImageGalleryStart({ isTenant, adminId: selectedPortal ? selectedClient : '', tenantId: isTenant ? tenantId : selectedPortal }))
  }, [selectedClient, selectedPortal])

  const handleDeleteYes = () => {
    setDeleteModalShow(false)
    const data = isTenant
      ? { imageUrl: imageDelete?.source?.replace(cdnHeader, s3Header), tenantId, tenantImageId: imageDelete?.tenantImageId, imageBucketType: imageDelete?.imageBucketType }
      : { imageUrl: imageDelete?.source?.replace(cdnHeader, s3Header), adminId: selectedPortal ? selectedClient : '', tenantId: selectedPortal, imageBucketType: imageDelete?.imageBucketType }
    dispatch(deleteFromGalleryStart({ isTenant, data }))
    setInitialState([])
  }

  useEffect(() => {
    setSelectedTab('common')
  }, [selectedClient])

  useEffect(() => {
    if (gallery?.length > 0) {
      const state = []
      for (const img in gallery) {
        const data = gallery[img]
        isTenant
          ? state.push({ name: data.name, source: (data?.imageBucketType === 'user' ? cdnHeader : s3Header) + data.imageUrl, tenantImageId: data?.tenantImageId, imageBucketType: data?.imageBucketType })
          : state.push({ name: data.name, source: (data?.imageBucketType === 'user' ? cdnHeader : s3Header) + data.imageUrl, imageBucketType: data?.imageBucketType })
      }
      setInitialState(state?.reverse())
    }
  }, [gallery, selectedTab])

  const customRequest = ({ uid, file, onProgress, onSuccess, onError }) => {
    if (!isHidden({ module: { key: 'ImageGallery', value: 'U' } })) {
      const action = `${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/gallery`

      let data = isTenant
        ? { name: file.name, image: file, tenantId, imageBucketType: selectedTab }
        : { name: file.name, image: file, adminId: selectedPortal ? selectedClient : '', tenantId: selectedPortal, imageBucketType: selectedTab }
      data = serialize(data)

      const CancelToken = axios.CancelToken
      const source = CancelToken.source()

      axios.put(
        action,
        data,
        {
          headers: {
            'Content-Type': 'multipart/formdata',
            Authorization: `Bearer ${getLoginToken()}`
          },
          onUploadProgress: ({ total, loaded }) => {
            onProgress(uid, Math.round(loaded / total * 100))
          },
          cancelToken: source.token
        }
      ).then(({ data: response }) => {
        const state = []
        dispatch(getImageGallerySuccess(response?.data?.gallery))
        for (const img in response?.data?.gallery) {
          const data = response?.data?.gallery[img]
          isTenant
            ? state.push({ name: data.name, source: (data?.imageBucketType === 'user' ? cdnHeader : s3Header) + data.imageUrl, tenantImageId: data?.tenantImageId })
            : state.push({ name: data.name, source: (data?.imageBucketType === 'user' ? cdnHeader : s3Header) + data.imageUrl })
        }
        onSuccess(uid, state[state?.length - 1])
        setInitialState(state)
        toast('Image Uploaded Successfully', 'success')
      })
        .catch(error => {
          onError(uid, {
            action,
            status: error.request,
            response: error.response
          })
        })
        .catch(error => {
          onError(uid, {
            action,
            status: error.request,
            response: error.response
          })
        })

      return {
        abort () {
          source.cancel()
        }
      }
    } else {
      toast('Upload Permission is Not Granted', 'error')
    }
  }

  return {
    isHidden,
    selectedTab,
    initialState,
    customRequest,
    selectedClient,
    setSelectedTab,
    selectedPortal,
    setImageDelete,
    deleteModalShow,
    handleDeleteYes,
    setSelectedClient,
    setSelectedPortal,
    setDeleteModalShow
  }
}

export default useUploadGallery
