import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from '../../../../../components/Toast'
import { createTABonusStart, getTABonusStart } from '../../../../../store/redux-slices/bonus'
import { formatDateYMD } from '../../../../../utils/dateFormatter'
import { safeStringify } from '../../../../../utils/helpers'

const useCloneBonus = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { bonusId } = useParams()
  const [set, setSet] = useState(false)
  const [portalOptions, setPortalOptions] = useState('')
  const [selectedTab, setSelectedTab] = useState('general')
  const [loyaltyCount, setLoyaltyCount] = useState(1)
  const [countryData, setCountryData] = useState([])

  const { bonusDetail, loading } = useSelector((state) => state.bonus)
  const { tenantsList } = useSelector((state) => state.tenants)
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ])

  const [data, setData] = useState({
    promoTitle: bonusDetail?.promotionTitle || { EN: '' },
    desc: bonusDetail?.description || { EN: '' },
    terms: bonusDetail?.termCondition || { EN: '' }
  })

  useEffect(() => {
    if (bonusDetail) {
      setCountryData(bonusDetail?.other?.countries)
      setData({
        promoTitle: bonusDetail?.promotionTitle,
        desc: bonusDetail?.description,
        terms: bonusDetail?.termCondition
      })
      setSet(true)
    }
  }, [bonusDetail])

  useEffect(() => {
    if (tenantsList) {
      const options = tenantsList.rows.map((portal) => { return ({ label: portal.name, value: portal.tenantId }) })
      setPortalOptions(options)
    }
  }, [tenantsList])

  useEffect(() => {
    if (bonusId) {
      dispatch(getTABonusStart({ bonusId, userBonusId: '' }))
    }
  }, [bonusId])

  const handelCreateBonus = ({ formValues, gameIds, languages, selectedTab, setSelectedTab }) => {
    Object.keys(formValues).forEach((key) => {
      if (formValues[key] === null || formValues[key] === '') {
        delete formValues[key]
      }
    })

    if (formValues.bonusType === 'promotion') {
      if ((selectedTab === 'countries')) {
        const myShowBonusValidity = formValues?.showBonusValidity
        delete formValues?.showBonusValidity
        formValues.promotionTitle = safeStringify(data?.promoTitle)
        formValues.description = safeStringify(data?.desc)
        formValues.termCondition = safeStringify(data?.terms)
        delete formValues?.loyaltyLevel
        delete formValues?.paymentMethods
        dispatch(
          createTABonusStart({
            data: {
              ...formValues,
              validFrom: formatDateYMD(state.map((a) => a.startDate)),
              validTo: formatDateYMD(state.map((a) => a.endDate)),
              tenantIds: formValues.tenantIds.map((id) => id.value),
              other: safeStringify({ countries: countryData, showBonusValidity: myShowBonusValidity })
            },
            navigate
          })
        )
      } else {
        setSelectedTab(selectedTab === 'general'
          ? (languages?.length ? 'languages' : 'countries')
          : 'countries')
      }
    } else if (formValues.bonusType === 'freespins' || formValues?.bonusType === 'cashfreespins') {
      if (selectedTab === 'countries') {
        const myShowBonusValidity = formValues?.showBonusValidity
        delete formValues?.showBonusValidity
        delete formValues?.loyaltyLevel
        delete formValues?.paymentMethods
        formValues.gameIds = gameIds
        formValues.promotionTitle = safeStringify(data?.promoTitle)
        formValues.description = safeStringify(data?.desc)
        formValues.termCondition = safeStringify(data?.terms)
        gameIds && gameIds?.length
          ? dispatch(
            createTABonusStart({
              data: {
                ...formValues,
                bonusType: 'freespins',
                validFrom: formatDateYMD(state.map((a) => a.startDate)),
                validTo: formatDateYMD(state.map((a) => a.endDate)),
                tenantIds: formValues.tenantIds.map((id) => id.value),
                other: safeStringify({ betLevel: formValues?.betLevel, countries: countryData, showBonusValidity: myShowBonusValidity })
              },
              navigate
            })
          )
          : toast('Select At least One Game.', 'error')
      } else {
        setSelectedTab(selectedTab === 'general'
          ? languages.length ? 'languages' : 'currency'
          : (selectedTab === 'languages'
              ? 'currency'
              : selectedTab === 'currency' && (formValues?.isSticky === 'true' || formValues?.isSticky)
                ? 'wageringContribution'
                : selectedTab === 'wageringContribution' || selectedTab === 'currency' ? 'games' : 'countries'))
      }
    } else if (formValues.bonusType === 'balance') {
      if (selectedTab === 'countries') {
        const myShowBonusValidity = formValues?.showBonusValidity
        delete formValues?.showBonusValidity
        formValues.promotionTitle = safeStringify(data?.promoTitle)
        formValues.description = safeStringify(data?.desc)
        formValues.termCondition = safeStringify(data?.terms)
        delete formValues?.paymentMethods
        dispatch(
          createTABonusStart({
            data: {
              ...formValues,
              validFrom: formatDateYMD(state.map((a) => a.startDate)),
              validTo: formatDateYMD(state.map((a) => a.endDate)),
              tenantIds: formValues.tenantIds.map((id) => id.value),
              appliedBonusId: formValues.appliedBonusVal,
              other: safeStringify({ countries: countryData, showBonusValidity: myShowBonusValidity })
            },
            navigate
          })
        )
      } else {
        setSelectedTab(selectedTab === 'general'
          ? (languages?.length ? 'languages' : 'countries')
          : 'countries')
      }
    } else {
      if (selectedTab === 'countries') {
        const myShowBonusValidity = formValues?.showBonusValidity
        if (['deposit', 'wagering', 'winBoost'].includes(formValues?.bonusType)) {
          for (let i = 0, len = formValues?.loyaltyLevel?.length; i < len; i++) {
            delete formValues?.loyaltyLevel?.[i].startPoint
            delete formValues?.loyaltyLevel?.[i].endPoint
            formValues.loyaltyLevel[i].bonusPercentage = formValues?.loyaltyLevel?.[i].bonusPercentage / 100
          }
        }
        delete formValues?.showBonusValidity
        const timePeriod = formValues?.timePeriod
        delete formValues?.timePeriod
        formValues.promotionTitle = safeStringify(data?.promoTitle)
        formValues.description = safeStringify(data?.desc)
        formValues.termCondition = safeStringify(data?.terms)
        formValues.paymentMethods = formValues?.paymentMethods ? safeStringify(formValues?.paymentMethods) : {}
        dispatch(
          createTABonusStart({
            data: {
              ...formValues,
              validFrom: formatDateYMD(state.map((a) => a.startDate)),
              validTo: formatDateYMD(state.map((a) => a.endDate)),
              tenantIds: formValues.tenantIds.map((id) => id.value),
              other: safeStringify({ loyaltyLevel: formValues?.loyaltyLevel, timePeriod, countries: countryData, showBonusValidity: myShowBonusValidity }),
              loyaltyLevel: ''
            },
            navigate
          })
        )
      } else {
        setSelectedTab(
          selected => selected === 'general'
            ? languages.length ? 'languages' : (formValues?.bonusType !== 'winBoost' ? 'currency' : 'loyalty')
            : (selected === 'languages'
                ? (formValues?.bonusType !== 'winBoost' ? 'currency' : 'loyalty')
                : selected === 'currency'
                  ? (['deposit', 'wagering', 'winBoost'].includes(formValues?.bonusType) ? 'loyalty' : 'payment')
                  : selectedTab === 'loyalty'
                    ? formValues?.bonusType === 'winBoost' ? 'countries' : 'wageringContribution'
                    : selectedTab === 'wageringContribution' ? 'countries' : 'wageringContribution')
        )
      }
    }
  }

  return {
    selectedTab,
    setSelectedTab,
    state,
    setState,
    dispatch,
    handelCreateBonus,
    bonusDetail,
    navigate,
    loading,
    tenantsList,
    portalOptions,
    setPortalOptions,
    data,
    setData,
    set,
    loyaltyCount,
    setLoyaltyCount,
    countryData,
    setCountryData
  }
}

export default useCloneBonus
