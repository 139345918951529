import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getBonusStart } from '../../store/redux-slices/bonus'
import { wageringRequirementType } from '../../pages/Super-Admin/Bonus/components/CreateBonus/constants'
import { useParams } from 'react-router-dom'

const useInfoModals = ({ isTenant, bonusId, currencyCode, userBonusId, bonusData }) => {
  const dispatch = useDispatch()
  const { bonusDetail } = useSelector(state => state.bonus)
  const { userId } = useParams()
  const [keys, setKeys] = useState([])

  const dataToShow = (key) => {
    switch (key) {
      case 'MATCH':
        setKeys([
          { label: 'Days To Clear', value: bonusDetail?.daysToClear },
          { label: 'Wagering Type', value: wageringRequirementType?.find(val => val.value === bonusDetail?.wageringRequirementType)?.label },
          { label: 'Wagering Multiplier', value: bonusData?.other?.wageringRequirement || bonusDetail?.wageringMultiplier },
          { label: 'Bonus Percentage', value: bonusDetail?.depositBonusPercent + '%' },
          { label: 'Min Deposit', value: bonusDetail?.currency?.[currencyCode]?.minDeposit }
        ])
        break

      case 'BALANCE':
        setKeys([
          { label: 'Applied Bonus', value: bonusDetail?.appliedBonusTitle?.EN + ' (' + bonusDetail?.appliedBonusId + ')' },
          { label: 'Min Balance Percentage', value: bonusDetail?.depositBonusPercent + '%' },
          { label: 'Days To Clear', value: bonusDetail?.daysToClear }
        ])
        break

      case 'WAGERING':
        setKeys([
          { label: 'Days To Clear', value: bonusDetail?.daysToClear },
          { label: 'Wagering Type', value: wageringRequirementType?.find(val => val.value === bonusDetail?.wageringRequirementType)?.label },
          { label: 'Wagering Multiplier', value: bonusDetail?.wageringMultiplier },
          { label: 'Min Wallet Balance', value: bonusDetail?.currency?.[currencyCode]?.minBalance }
        ])
        break

      default:
        break
    }
    return keys
  }

  useEffect(() => {
    bonusDetail?.bonusType &&
            bonusDetail?.bonusType !== 'freespins' &&
                dataToShow(bonusDetail?.bonusType?.toUpperCase())
  }, [bonusDetail?.bonusType, bonusId])

  useEffect(() => {
    if (bonusId && userBonusId) {
      dispatch(getBonusStart({ bonusId, isTenant, userId, userBonusId }))
    }
  }, [bonusId, userBonusId])

  return {
    bonusDetail,
    keys
  }
}

export default useInfoModals
