import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  Form as BForm,
  Row,
  Col,
  Badge,
  Spinner,
  InputGroup
} from '@themesberg/react-bootstrap'
import { Form, Field, ErrorMessage } from 'formik'
import { toast } from '../../../../components/Toast'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons'
import CreatableSelect from 'react-select/creatable'
import { getGroupDetailStart } from '../../../../store/redux-slices/admins'
import Trigger from '../../../../components/OverlayTrigger'
import { TenantRoutes } from '../../../../routes'
import Select from 'react-select'
import { getMatchingValues } from '../../../../utils/helpers'
import { getAdminUserDetails } from '../../../../utils/apiCalls'
import { getAllTenantAdminsStart } from '../../../../store/redux-slices/tenantsFetchData'

const AdminForm = ({
  values,
  handleBlur,
  handleChange,
  handleSubmit,
  userDetails,
  groupOptions,
  setGroupOptions,
  setFieldValue,
  selectedGroup,
  setSelectedGroup,
  type,
  setType,
  dispatch,
  adminDetails,
  getPermissionLabel,
  navigate,
  loading,
  groupDetail,
  tenantIdOptions,
  tenantAdminRole,
  tenantAdminsData
}) => {
  useEffect(() => {
    if (values.role === 'Support') {
      adminDetails?.AdminRole?.name !== 'Manager' && dispatch(getAllTenantAdminsStart({ limit: '', pageNo: '', sort: '', orderBy: '', search: '', status: '' }))
      // values?.adminId && getAdminDetails({ adminId: values.adminId })
    }
  }, [values.role])

  const [data, setData] = useState()

  useEffect(() => {
    if (values?.adminId && adminDetails?.AdminRole?.name !== 'Manager') {
      function fetchData () {
        getAdminUserDetails({ adminUserId: values?.adminId, isTenant: true })
          .then((res) => {
            setData(res?.data?.data?.adminDetails)
          })
      }
      fetchData()
    }
  }, [values?.adminId])

  useEffect(() => {
    if (groupDetail) {
      const permission = getMatchingValues((values.role === 'Support' ? (adminDetails?.AdminRole?.name === 'Manager' ? adminDetails : data) : adminDetails)?.userPermission?.permission, groupDetail?.permissions)
      setFieldValue('permission', permission)
    }
  }, [groupDetail, adminDetails, data])

  return (
    <Form>
      <Row>
        <Col>
          <BForm.Label>Email
            <span className='text-danger'> *</span>
          </BForm.Label>

          <BForm.Control
            type='text'
            name='email'
            placeholder='Enter Email'
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={userDetails}
          />

          <ErrorMessage
            component='div'
            name='email'
            className='text-danger'
          />
        </Col>

        <Col>
          <BForm.Label>Phone Number</BForm.Label>

          <BForm.Control
            type='text'
            name='phone'
            placeholder='Enter Phone'
            value={values.phone}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <ErrorMessage
            component='div'
            name='phone'
            className='text-danger'
          />
        </Col>
      </Row>
      <Row className='mt-3'>
        <Col>
          <BForm.Label>First Name
            <span className='text-danger'> *</span>
          </BForm.Label>

          <BForm.Control
            type='text'
            name='firstName'
            placeholder='Enter First Name'
            value={values.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <ErrorMessage
            component='div'
            name='firstName'
            className='text-danger'
          />
        </Col>
        <Col>
          <BForm.Label>Last Name
            <span className='text-danger'> *</span>
          </BForm.Label>

          <BForm.Control
            type='text'
            name='lastName'
            placeholder='Enter Last Name'
            value={values.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <ErrorMessage
            component='div'
            name='lastName'
            className='text-danger'
          />
        </Col>
      </Row>
      <Row className='mt-3'>
        <Col>
          <BForm.Label>Username
            <span className='text-danger'> *</span>
          </BForm.Label>

          <BForm.Control
            type='text'
            name='agentName'
            placeholder='Enter Username'
            value={values.agentName}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={userDetails}
          />

          <ErrorMessage
            component='div'
            name='agentName'
            className='text-danger'
          />
        </Col>
        <Col>
          <BForm.Label>Group
            <span className='text-danger'> *</span>
          </BForm.Label>

          <CreatableSelect
            isClearable
            name='group'
            onCreateOption={(option) => {
              groupOptions?.length > 0
                ? setGroupOptions([
                  ...groupOptions,
                  { label: option, value: option }
                ])
                : setGroupOptions([
                  { label: option, value: option }
                ])
              setSelectedGroup({ label: option, value: option })
              setFieldValue('group', option)
            }}
            options={groupOptions}
            value={selectedGroup}
            classNamePrefix='select'
            onChange={(option, e) => {
              if (option === null) {
                setSelectedGroup()
                setFieldValue('group', '')
              } else {
                setSelectedGroup({ label: option?.label, value: option?.value })
                setFieldValue('group', option?.value)
              }
            }}
          />

          <ErrorMessage
            component='div'
            name='group'
            className='text-danger'
          />
        </Col>
      </Row>

      <Row className='mt-3'>

        {!userDetails &&
          <Col sm={12} md={6}>
            <BForm.Label>Password
              <span className='text-danger'> *</span>
            </BForm.Label>

            <Trigger message='Must be atleast 8 characters long with 1 uppercase and 1 lowercase letters, 1 special character and 1 digit'>
              <InputGroup>
                <BForm.Control
                  type={type}
                  name='password'
                  placeholder='Enter Password'
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <InputGroup.Text className='b-1' style={{ border: '0.0625rem solid #d1d7e0' }}>
                  <FontAwesomeIcon
                    icon={type === 'password' ? faEyeSlash : faEye}
                    onClick={() => {
                      type === 'password' ? setType('text') : setType('password')
                    }}
                  />
                </InputGroup.Text>
              </InputGroup>
            </Trigger>

            <ErrorMessage
              component='div'
              name='password'
              className='text-danger'
            />
          </Col>}

        <Col>
          <BForm.Label>Role<span className='text-danger'> *</span></BForm.Label>

          <BForm.Select
            name='role'
            value={values.role || ''}
            disabled={userDetails}
            onChange={(e) => {
              handleChange(e)
              setFieldValue('adminId', '')
              if (e.target.value !== 'Support') {
                // superAdminDetails()
              } else {
                setFieldValue('permission', {})
                setFieldValue(
                  'adminId',
                  adminDetails?.AdminRole?.name === 'Manager'
                    ? parseInt(adminDetails?.adminUserId)
                    : '')
              }
            }}
            onBlur={handleBlur}
          >
            <option value='' disabled key=''>
              Select Role
            </option>
            {tenantAdminRole?.map((roles, index) => {
              return (
                (adminDetails?.AdminRole?.name === 'Manager'
                  ? roles.name !== 'Manager' && roles.name !== 'Owner'
                  : roles.name !== 'Owner') &&
                    <option key={index} value={roles && roles.name}>
                      {roles && roles.name}
                    </option>
              )
            })}
          </BForm.Select>

          <ErrorMessage component='div' name='role' className='text-danger' />
        </Col>
      </Row>

      <Row className='mt-3'>
        {(adminDetails?.AdminRole?.name === 'Owner' ? values.role === 'Support' : false) && (
          <Col sm={6}>
            <BForm.Label>Manager<span className='text-danger'> *</span></BForm.Label>

            <BForm.Select
              name='adminId'
              value={values.adminId || ''}
              disabled={userDetails}
              onChange={(e) => {
                handleChange(e)
                // getAdminDetails({ adminId: e.target.value })
              }}
              onBlur={handleBlur}
            >
              <option value='' disabled>
                Select Manager
              </option>
              {tenantAdminsData &&
                  tenantAdminsData?.rows?.map((admin, index) => {
                    return (
                      <option
                        key={index}
                        value={admin && admin.adminUserId}
                      >
                        {admin && `${admin?.firstName} ${admin?.lastName}`}
                      </option>
                    )
                  })}
            </BForm.Select>

            <ErrorMessage
              component='div'
              name='adminId'
              className='text-danger'
            />
          </Col>
        )}

        {values?.role &&
          (values?.role === 'Support' ? values?.adminId : true) &&
           (
             <Col xs={6}>
               <BForm.Label>Tenants<span className='text-danger'> *</span></BForm.Label>
               <Select
                 isMulti
                 isClearable={false}
                 name='tenantIds'
                 options={values.role === 'Support' ? adminDetails?.AdminRole?.name === 'Manager' ? tenantIdOptions : tenantIdOptions?.filter(({ value }) => data?.tenantIds?.includes(value)) : tenantIdOptions}
                 value={values?.tenantIds}
                 className='basic-multi-select'
                 classNamePrefix='select'
                 onChange={(option) => {
                   setFieldValue('tenantIds', option)
                 }}
               />

               <ErrorMessage
                 component='div'
                 name='tenantIds'
                 className='text-danger'
               />
             </Col>)}
      </Row>

      {(['Owner', 'Manager'].includes(values?.role) ||
          values.adminId) && (
            <Card className='mt-3'>
              <Card.Header>
                <div className='d-flex align-items-center'>
                  <span className='col-4 header-font-weight'>
                    Module
                  </span>
                  <span className='ms-3 header-font-weight'>
                    Permissions
                  </span>&nbsp;
                  {values?.group &&
                    <Button
                      size='sm'
                      variant='outline-success'
                      onClick={() => dispatch(getGroupDetailStart({ isTenant: true, groupId: '', groupName: selectedGroup?.value }))}
                    >
                      Fetch permission
                    </Button>}
                </div>
              </Card.Header>
              {(values.role === 'Support' ? (adminDetails?.AdminRole?.name === 'Manager' ? adminDetails : data) : adminDetails)?.userPermission && (
                <Card.Body>
                  {Object.keys(
                    (values.role === 'Support' ? (adminDetails?.AdminRole?.name === 'Manager' ? adminDetails : data) : adminDetails)?.userPermission?.permission
                  ).map((key, index) => {
                    return (
                      ((values.role === 'Support' && key === 'Admins')
                        ? null
                        : (
                          <Row className='border-bottom mt-3' key={index}>
                            <Col lg={4}>
                              <BForm.Label>{key}</BForm.Label>
                            </Col>

                            <Col className='d-flex flex-wrap'>
                              {(values.role === 'Support' ? (adminDetails?.AdminRole?.name === 'Manager' ? adminDetails : data) : adminDetails)?.userPermission?.permission[
                                key
                              ].map((value, index) => {
                                return (
                                  <label key={index}>
                                    {(values.role === 'Support' ? (adminDetails?.AdminRole?.name === 'Manager' ? adminDetails : data) : adminDetails)?.userPermission?.permission[
                                      key
                                    ].includes('R')
                                      ? (
                                        <Field
                                          className='d-none'
                                          type='checkbox'
                                          name={`permission[${key}]`}
                                          value={value}
                                          onChange={(e) => {
                                            if (e.target.value === 'R' || values?.permission?.[key]?.includes('R')) {
                                              if (e.target.value === 'R' && !e.target.checked) {
                                                const permission = { ...values?.permission }
                                                delete permission?.[key]
                                                setFieldValue(
                                                  'permission',
                                                  permission
                                                )
                                              } else {
                                                handleChange(e)
                                              }
                                            } else {
                                              toast(
                                                'Please Select Read Permission Before Selecting Other For This Module',
                                                'error'
                                              )
                                            }
                                          }}
                                        />
                                        )
                                      : (
                                        <Field
                                          className='d-none'
                                          type='checkbox'
                                          name={`permission[${key}]`}
                                          value={value}
                                          onChange={handleChange}
                                        />
                                        )}

                                    <h3>
                                      <Badge
                                        className='p-2 mx-2 '
                                        type='button'
                                        bg={
                                          values?.permission?.[key]?.includes(value)
                                            ? 'success'
                                            : 'primary'
                                        }
                                      >
                                        {getPermissionLabel(value, key)}
                                      </Badge>
                                    </h3>
                                  </label>
                                )
                              })}
                            </Col>
                          </Row>)
                      ))
                  })}

                </Card.Body>
              )}
            </Card>
      )}

      <div className='mt-4 d-flex justify-content-between align-items-center'>
        <Button
          variant='outline-warning'
          onClick={() => navigate(TenantRoutes.Admins)}
        >
          Cancel
        </Button>
        <Button
          variant='outline-success'
          onClick={handleSubmit}
          className='ml-2'
        >
          Submit
          {loading && (
            <Spinner
              as='span'
              animation='border'
              size='sm'
              role='status'
              aria-hidden='true'
            />
          )}
        </Button>
      </div>
    </Form>
  )
}

export default AdminForm
