import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { addUserInternalTagtStart } from '../../../store/redux-slices/admins'
import { issueBonusStart } from '../../../store/redux-slices/bonus'
import { getUserStart } from '../../../store/redux-slices/fetchData'
import { updateSAUserStatusStart } from '../../../store/redux-slices/superAdminSettings'
import { addAffiliateUserStart, clearAffiliateData, getDuplicateUsersStart, resetPasswordEmailStart, resetUserPasswordStart, updateTagsStart, updateUserAffiliateStart, verifyUserEmailStart } from '../../../store/redux-slices/tenantUsers'
import { getUserStatusStart } from '../../../store/redux-slices/adminUser'
import { getDateDaysAgo } from '../../../utils/dateFormatter'
import { clearPaymentMethods } from '../../../store/redux-slices/payment'
import { checkIsTenant } from '../../../utils/constants'

const useUserDetails = ({ isTenant }) => {
  const navigate = useNavigate()
  const { userId } = useParams()
  const dispatch = useDispatch()
  const [selectedTab, setSelectedTab] = useState('overview')
  const { userData, loading } = useSelector((state) => state.fetch)

  // Audit Logs
  const [auditsPageNo, setAuditsPageNo] = useState(1)
  const [auditsLimit, setAuditsLimit] = useState(15)
  const [auditsSearch, setAuditsSearch] = useState('')
  const [auditsState, setAuditsState] = useState([
    {
      startDate: getDateDaysAgo(10),
      endDate: new Date(),
      key: 'selection'
    }
  ])

  // Audit Logs
  const [sessionLogsPageNo, setSessionLogsPageNo] = useState(1)
  const [sessionLogsLimit, setSessionLogsLimit] = useState(15)
  const [sessionLogsSearch, setSessionLogsSearch] = useState('')
  const [sessionLogsState, setSessionLogsState] = useState([
    {
      startDate: getDateDaysAgo(10),
      endDate: new Date(),
      key: 'selection'
    }
  ])

  // Affiliate Form
  const [affiliateState, setAffiliateState] = useState([
    {
      startDate: getDateDaysAgo(0),
      endDate: new Date(),
      key: 'selection'
    }
  ])

  // Email Logs
  const [emailLimit, setEmailLimit] = useState(15)
  const [emailPageNo, setEmailPageNo] = useState(1)
  const [emailSearch, setEmailSearch] = useState('')
  const [emailRole, setEmailRole] = useState('')

  // Comments
  const [commentsLimit, setCommentsLimit] = useState(15)
  const [commentsPageNo, setCommentsPageNo] = useState(1)
  const [commentsSearch, setCommentsSearch] = useState('')
  const [commentsRole, setCommentsRole] = useState('')
  const [commentsStatus, setCommentsStatus] = useState('')

  // Your Bonuses
  const [bonusLimit, setBonusLimit] = useState(10)
  const [bonusPage, setBonusPage] = useState(1)
  const [bonusStatus, setBonusStatus] = useState('')
  const [bonusType, setBonusType] = useState('')

  // Game Report
  const [gameSelectedTab, setGameSelectedTab] = useState('game')
  const [gameDateOptions, setGameDateOptions] = useState('today')
  const [gameLimit, setGameLimit] = useState(10)
  const [gameState, setGameState] = useState([
    {
      startDate: getDateDaysAgo(0),
      endDate: new Date(),
      key: 'selection'
    }
  ])

  // Overview Tab Start From Here

  // Basic Details
  const [count, setCount] = useState(0)
  const { adminPermissions } = useSelector(state => state.admins)

  useEffect(() => {
    dispatch(getUserStart({ userId, isTenant }))
    fetchDetails()

    return () => {
      dispatch(clearAffiliateData())
      dispatch(clearPaymentMethods())
    }
  }, [userId])

  const fetchDetails = () => {
    dispatch(getUserStatusStart({ userId, isTenant }))
  }

  useEffect(() => {
    if (userId && selectedTab === 'overview') {
      const intervals = setInterval(() => {
        clearInterval(intervals)
        fetchDetails()
        setCount(count + 1)
      }, 30000)
    }
  }, [userId, count])

  const reloadUserStatus = () => {
    fetchDetails()
  }

  // Mark Active In-active
  const [isActiveInactivemodalVisible, setActiveInactiveModal] = useState(false)

  const updateUserStatus = (res) => {
    dispatch(updateSAUserStatusStart({
      data: {
        ...res,
        code: 'USER',
        userId,
        status: !userData.status,
        tenantId: userData.tenantId
      },
      isTenant
    }))
    setTimeout(() => {
      dispatch(getUserStart({ userId, isTenant }))
    }, 1000)
  }

  // Verify Email
  const [verifyEmailModal, setVerifyEmailModal] = useState(false)

  const handleVerifyEmail = () => {
    dispatch(verifyUserEmailStart({ isTenant, data: { userId: parseInt(userId) } }))
    setVerifyEmailModal(false)
  }

  // Internal
  const [isInternalModal, setIsInternalModal] = useState(false)

  const internalPlayerHandler = async () => {
    await dispatch(addUserInternalTagtStart({ data: { userId: parseInt(userId), tenantId: userData?.tenantId }, isTenant }))
    setIsInternalModal(false)
    setTimeout(() => {
      dispatch(getUserStart({ userId, isTenant }))
    }, 1000)
  }

  // Manage Tag
  const [tagOptions, setTagOptions] = useState()
  const [selectedTag, setSelectedTag] = useState()
  const [showTagModal, setShowTagModal] = useState(false)

  const addTag = () => {
    setShowTagModal(true)
  }

  useEffect(() => {
    if (userData?.tags?.length > 0) {
      const options = []
      for (const i in userData?.tags) {
        if (userData?.tags[i] !== '' && userData?.tags[i] !== null) {
          options.push({ label: userData?.tags[i], value: userData?.tags[i] })
        }
      }
      setTagOptions(options)
    }
  }, [userData?.tags])

  useEffect(() => {
    const tag = []
    for (const i in userData?.tags) {
      userData?.tags[i] !== null && tag.push({ label: userData?.tags[i], value: userData?.tags[i] })
    }
    setSelectedTag(tag)
  }, [userData?.tags])

  const updateTags = (formValues) => {
    const tags = []
    let customTag = false
    for (const tag in formValues?.tags) {
      tags.push(formValues?.tags[tag]?.value)
      if (formValues?.tags[tag]?.isNew) {
        customTag = true
      }
    }
    dispatch(updateTagsStart({
      data: {
        tenantId: JSON.parse(userData?.tenantId),
        userId: JSON.parse(userId),
        parentId: userData.parentId,
        tags,
        customTag
      },
      isTenant
    }))
  }

  // Duplicates
  const [duplicateUsersLimit, setDuplicateUsersLimit] = useState(15)
  const [duplicateUsersPageNo, setDuplicateUsersPageNo] = useState(1)
  const [showDuplicateModal, setShowDuplicateModal] = useState(false)
  const { duplicateUsers, emailLoading } = useSelector(state => state.tenantUsers)

  const getDuplicateUsers = () => {
    setShowDuplicateModal(true)
  }

  useEffect(() => {
    dispatch(getDuplicateUsersStart({
      limit: duplicateUsersLimit,
      pageNo: duplicateUsersPageNo,
      userId,
      isTenant
    }))
  }, [duplicateUsersLimit, duplicateUsersPageNo])

  // Manage Money
  const [showManageMoneyModal, setShowManageMoneyModal] = useState(false)

  // Payment Modal
  const [showPaymentModal, setShowPaymentModal] = useState(false)

  // Reset Password
  const [userPasswordModal, setUserPasswordModal] = useState(false)
  const [passwordEmailModal, setPasswordEmailModal] = useState(false)

  const handleResetPasswordEmail = () => {
    dispatch(resetPasswordEmailStart({
      isTenant,
      data: { userId: parseInt(userData?.userId) }
    }))
    setPasswordEmailModal(false)
  }

  const handleResetUserPassword = (data) => {
    dispatch(resetUserPasswordStart({
      isTenant,
      ...data
    }))
    setUserPasswordModal(false)
  }

  // Edit User Info
  const [editUserModal, setEditUserModal] = useState(false)

  // Affliate Add and Remove
  const [showAddAffiliate, setShowAddAffiliate] = useState(false)
  const [showRemoveAffiliate, setShowRemoveAffiliate] = useState(false)
  const { updateUserAffiliateLoading, addUserAffiliateLoading } = useSelector((state) => state.fetch)

  const addAffiliateHandler = () => {
    if (userData?.trackingToken && userData?.isAffiliateUpdated === false) {
      dispatch(addAffiliateUserStart({ data: { userId, tenantId: userData?.tenantId, trackingToken: userData?.trackingToken }, isSuperAdmin: true, setShowAddAffiliate }))
    }
  }

  const removeAffiliateHandler = () => {
    dispatch(updateUserAffiliateStart({ data: { userId, tenantId: userData?.tenantId, affiliateStatus: false }, isSuperAdmin: !checkIsTenant(), setShowRemoveAffiliate }))
  }

  // Freespin Modal
  const [freespinModal, setFreeSpinModal] = useState(false)

  // Bonus Issue Modal
  const [bonusAmount, setBonusAmount] = useState()
  const [showModal, setShowModal] = useState(false)
  const [selectedBonus, setSelectedBonus] = useState()
  const { bonusList } = useSelector((state) => state.admins)
  const { bonusDetail } = useSelector((state) => state.bonus)

  const issueBonusHandler = () => {
    setShowModal(false)
    dispatch(issueBonusStart({ data: { userId: parseInt(userId), bonusId: selectedBonus?.bonusId, amount: bonusAmount, adminRequest: !!Number(bonusAmount) }, isTenant }))
    setBonusAmount('')
  }

  // Verify Email
  const [verifyPhoneModal, setVerifyPhoneModal] = useState(false)

  const handleVerifyPhoneModal = () => {
    dispatch(verifyUserEmailStart({ isTenant, data: { userId: parseInt(userId) }, phone: true }))
    setVerifyPhoneModal(false)
  }

  return {
    loading,
    navigate,
    userData,
    selectedTab,
    emailLoading,
    setSelectedTab,

    // Audit Logs
    auditsPageNo,
    setAuditsPageNo,
    setAuditsLimit,
    auditsLimit,
    auditsSearch,
    setAuditsSearch,
    auditsState,
    setAuditsState,

    // Session Logs
    sessionLogsPageNo,
    setSessionLogsPageNo,
    setSessionLogsLimit,
    sessionLogsLimit,
    sessionLogsSearch,
    setSessionLogsSearch,
    sessionLogsState,
    setSessionLogsState,

    // Affiliate Form
    affiliateState,
    setAffiliateState,

    // Email Logs
    emailLimit,
    setEmailLimit,
    emailRole,
    setEmailRole,
    emailPageNo,
    setEmailPageNo,
    emailSearch,
    setEmailSearch,

    // Comments
    commentsLimit,
    setCommentsLimit,
    commentsPageNo,
    setCommentsPageNo,
    commentsSearch,
    setCommentsSearch,
    commentsRole,
    setCommentsRole,
    commentsStatus,
    setCommentsStatus,

    // Your Bonuses
    bonusLimit,
    setBonusLimit,
    bonusPage,
    setBonusPage,
    bonusStatus,
    setBonusStatus,
    bonusType,
    setBonusType,

    // Game Report
    gameSelectedTab,
    setGameSelectedTab,
    gameDateOptions,
    setGameDateOptions,
    gameLimit,
    setGameLimit,
    gameState,
    setGameState,

    // Phone Verification
    verifyPhoneModal,
    setVerifyPhoneModal,
    handleVerifyPhoneModal,

    // Overview
    adminPermissions,
    reloadUserStatus,
    setActiveInactiveModal,
    isActiveInactivemodalVisible,
    verifyEmailModal,
    setVerifyEmailModal,
    isInternalModal,
    setIsInternalModal,
    internalPlayerHandler,
    setShowTagModal,
    showTagModal,
    addTag,
    updateTags,
    tagOptions,
    setTagOptions,
    selectedTag,
    setSelectedTag,
    duplicateUsersLimit,
    setDuplicateUsersLimit,
    duplicateUsersPageNo,
    setDuplicateUsersPageNo,
    showManageMoneyModal,
    setShowManageMoneyModal,
    showPaymentModal,
    setShowPaymentModal,
    userPasswordModal,
    setUserPasswordModal,
    passwordEmailModal,
    setPasswordEmailModal,
    handleResetPasswordEmail,
    handleResetUserPassword,
    showAddAffiliate,
    setShowAddAffiliate,
    showRemoveAffiliate,
    setShowRemoveAffiliate,
    addAffiliateHandler,
    removeAffiliateHandler,
    addUserAffiliateLoading,
    updateUserAffiliateLoading,
    freespinModal,
    setFreeSpinModal,
    bonusAmount,
    setBonusAmount,
    showModal,
    setShowModal,
    selectedBonus,
    setSelectedBonus,
    issueBonusHandler,
    bonusDetail,
    bonusList,
    updateUserStatus,
    handleVerifyEmail,
    editUserModal,
    setEditUserModal,
    showDuplicateModal,
    setShowDuplicateModal,
    getDuplicateUsers,
    duplicateUsers
  }
}

export default useUserDetails
