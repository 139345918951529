import { Button, Col, Row, Form } from '@themesberg/react-bootstrap'
import React from 'react'
import { statusType } from './../../TransactionsBanking/constants'
import useTransactionBList from './../../TransactionsBanking/hooks/useTransactionBList'
import DateRangePicker from '../../../../components/DateRangePicker'
import { getDateDaysAgo } from '../../../../utils/dateFormatter'
import TransactionsList from '../../../../components/TransactionsList'
import Preloader from '../../../../components/Preloader'
import Trigger from '../../../../components/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload, faRedoAlt } from '@fortawesome/pro-solid-svg-icons'
import { handleDownloadCSV } from '../../../../utils/fileDownloader'
import ActionTypeSelect from '../../../../components/ActionTypeSelect'
import TransactionSelects from '../../../../components/ActionTypeSelect/TransactionSelects'

const TransactionBanking = ({ isTenant = false }) => {
  const {
    page,
    limit,
    state,
    check,
    search,
    status,
    userId,
    loading,
    setPage,
    setState,
    setLimit,
    userData,
    setCheck,
    createdAt,
    setStatus,
    setSearch,
    totalPages,
    transactions,
    setCreatedAt,
    transactionId,
    allCurrencies,
    paymentMethod,
    selectedAction,
    selectedPortal,
    selectedClient,
    selectedCountry,
    transactionHover,
    selectedCurrency,
    setTransactionId,
    setPaymentMethod,
    setSelectedClient,
    getCsvDownloadUrl,
    allowedCurrencies,
    setSelectedAction,
    transactionsSortBy,
    setTransactionHover,
    setSelectedCurrency,
    setTransactionsSortBy,
    selectedPaymentProvider,
    setSelectedPaymentProvider,
    transactionPaymentCategories,
    transactionReportingPaymentCategories
  } = useTransactionBList({ isUserDetail: true, isTenant })

  return (
    <>

      <>
        <Row className='mt-5 mb-5 search-section'>
          <Col xs='auto' className='d-flex mb-3'>
            <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
              Currency
            </Form.Label>

            <Form.Select
              size='sm'
              style={{ maxWidth: '230px' }}
              onChange={(e) => setSelectedCurrency(e.target.value)}
              value={selectedCurrency}
            >
              <option value=''>Select a Currency</option>
              {isTenant
                ? allowedCurrencies && allowedCurrencies?.map(
                  (code) => (
                    <option key={code} value={code}>
                      {code}
                    </option>
                  ))
                : allCurrencies && allCurrencies?.rows?.map(
                  ({ name: currName, currencyId, code }) => (
                    <option key={currencyId} value={code}>
                      {currName}
                    </option>
                  )
                )}
            </Form.Select>
          </Col>

          <Col xs='auto' className='d-flex mb-3'>
            <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
              Action Type
            </Form.Label>

            <ActionTypeSelect selectedAction={selectedAction} setSelectedAction={setSelectedAction} />

          </Col>

          <Col xs='auto' className='d-flex mb-3'>
            <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
              Status
            </Form.Label>

            <Form.Select
              size='sm'
              style={{ maxWidth: '230px' }}
              value={status}
              onChange={e =>
                setStatus(e.target.value.replace(/[~`!$%^&*#=)()><?]+/g, ''))}
            >
              {statusType.map(({ label, value }) => {
                return <option key={label} value={value}>{label}</option>
              })}
            </Form.Select>
          </Col>

          <Col xs='auto' className='d-flex mb-3'>
            <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
              Payment Provider
            </Form.Label>

            <TransactionSelects
              transactionReportingPaymentCategories={transactionPaymentCategories}
              paymentMethod={selectedPaymentProvider}
              setPaymentMethod={setSelectedPaymentProvider}
              placeholder='Payment Provider'
            />
          </Col>

          <Col xs='auto' className='d-flex mb-3'>
            <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
              Payment Method
            </Form.Label>
            <TransactionSelects
              transactionReportingPaymentCategories={transactionReportingPaymentCategories}
              paymentMethod={paymentMethod}
              setPaymentMethod={setPaymentMethod}
              placeholder='Payment Method'
            />
          </Col>

          <Col style={{ maxHeight: '25px' }} xs='auto' className='d-flex mb-3 align-items-center'>
            <Form.Label style={{ width: 'max-content' }} column='sm' className='text-nowrap px-2'>
              Created At
            </Form.Label>
            <Form.Check
              name='check1'
              className='px-2'
              value={check.createdAt}
              checked={check.createdAt}
              onChange={() => setCheck({ ...check, createdAt: !check.createdAt })}
            />
            <DateRangePicker
              state={createdAt} setState={setCreatedAt}
              player
              disabled={!check.createdAt}
            />
          </Col>

          <Col xs='auto' className='d-flex mb-3'>
            <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
              Updated At
            </Form.Label>
            <Form.Check
              name='check2'
              className='px-2'
              value={check.updatedAt}
              checked={check.updatedAt}
              onChange={() => setCheck({ ...check, updatedAt: !check.updatedAt })}
            />
            <DateRangePicker
              state={state} setState={setState} transaction bonus
              disabled={!check.updatedAt}
            />
          </Col>

          <Col xs='auto' className='d-flex mb-3'>
            <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
              Transaction Id
            </Form.Label>

            <Form.Control
              size='sm'
              placeholder='Search By Transaction ID'
              style={{ maxWidth: '230px' }}
              onChange={(e) => setTransactionId(e.target.value)}
              value={transactionId}
            />
          </Col>

          <Col xs='auto' className='d-flex mb-3'>
            <Trigger message='Reset Filters'>
              <Button
                variant='outline-success'
                className=''
                size='sm'
                onClick={() => {
                  setSearch('')
                  setSelectedAction(JSON.stringify([]))
                  setSelectedCurrency('')
                  setLimit(15)
                  setPage(1)
                  setSelectedPaymentProvider('[]')
                  setPaymentMethod('[]')
                  setCheck({ createdAt: false, updatedAt: true })
                  setSelectedClient('')
                  setState([
                    {
                      startDate: getDateDaysAgo(10),
                      endDate: new Date(),
                      key: 'selection'
                    }
                  ])
                  setCreatedAt([
                    {
                      startDate: getDateDaysAgo(10),
                      endDate: new Date(),
                      key: 'selection'
                    }
                  ])
                  setTransactionId('')
                }}
              >
                <FontAwesomeIcon icon={faRedoAlt} />
              </Button>
            </Trigger>
            <Trigger message='Download as CSV'>
              <Button
                variant='outline-success'
                size='sm'
                style={{ marginLeft: '10px' }}
                disabled={transactions?.count === 0}
                onClick={() => { handleDownloadCSV(getCsvDownloadUrl('player'), `Transaction_Banking)_${userData?.email}`) }}
              >
                <FontAwesomeIcon icon={faFileDownload} />
              </Button>
            </Trigger>
          </Col>
        </Row>
        {loading
          ? <Preloader />
          : (
            <TransactionsList
              page={page}
              setLimit={setLimit}
              limit={limit}
              setPage={setPage}
              totalPages={totalPages}
              transactions={transactions}
              isTenant={false}
              selectedCurrency={selectedCurrency}
              selectedAction={selectedAction}
              userDetail
              state={state}
              selectedStatus={status}
              paymentProvider={selectedPaymentProvider}
              countryCode={selectedCountry}
              adminId={selectedClient}
              tenantId={selectedPortal}
              actioneeType={search}
              playerId={userId}
              myTransactionId={transactionId}
              createdAt={createdAt}
              paymentMethod={paymentMethod}
              check={check}
              transactionsSortBy={transactionsSortBy}
              setTransactionsSortBy={setTransactionsSortBy}
              transactionHover={transactionHover}
              setTransactionHover={setTransactionHover}
            />)}
      </>
    </>
  )
}

export default TransactionBanking
