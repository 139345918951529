import { deleteRequest, getRequest, postRequest, putRequest } from './axios'
import { checkIsTenant } from './constants'
import { safeStringify } from './helpers'

const { REACT_APP_API_URL } = process.env

const superAdminLogin = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/superadmin/login`, data)

const tenantLogin = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/tenant/admin/login`, data)

const getAllTenants = ({ limit, pageNo, search, adminId, status }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/superadmin/tenant?limit=${limit}&pageNo=${pageNo}&search=${search}&adminId=${adminId}&status=${status}`
  )
const getThemeData = () =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/themes`)

const getAllThemes = ({ pageNo, limit }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/superadmin/themes?limit=${limit}&pageNo=${pageNo}`
  )

const createTenant = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/superadmin/tenant`, data)

const getAllAdmins = ({
  limit,
  pageNo,
  orderBy,
  sort,
  search,
  superAdminId,
  superRoleId,
  status
}) =>
  getRequest(
    `${REACT_APP_API_URL}/api/superadmin?orderBy=${orderBy}&pageNo=${pageNo}&limit=${limit}&sort=${sort}&search=${search}&superRoleId=${superRoleId}&superAdminId=${superAdminId}&status=${status}`
  )

const getAllTenantAffiliates = ({ limit, pageNo, search }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/tenant/affiliate/all?pageNo=${pageNo}&limit=${limit}&search=${search}`
  )

const getTenantAffiliateById = ({ affiliateId }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/tenant/affiliate?affiliateId=${affiliateId}`
  )

const getAllTenantAdmins = ({ limit, pageNo, orderBy, sort, search, status }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/tenant/admin?orderBy=${orderBy}&limit=${limit}&pageNo=${pageNo}&sort=${sort}&search=${search}&status=${status}`
  )

const getAllTenantCMS = ({ limit, pageNo, search, isActive }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/tenant/cms?pageNo=${pageNo}&limit=${limit}&search=${search}&isActive=${isActive}`
  )

const getAllThemesTenant = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/themes?limit=50&pageNo=1`)

const getAllCurrencies = ({ limit, pageNo }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/${checkIsTenant() ? 'tenant' : 'superadmin'}/currency?limit=${limit}&pageNo=${pageNo}`
  )

const getAllTenantCurrencies = ({ limit, pageNo }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/tenant/currencies?limit=${limit}&pageNo=${pageNo}`
  )

const getAllAffiliates = ({ limit, pageNo, tenantId, search }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/superadmin/affiliate/all?limit=${limit}&pageNo=${pageNo}&tenantId=${tenantId}&search=${search}`
  )

const getAffiliateById = ({ affiliateId }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/superadmin/affiliate?affiliateId=${affiliateId}`
  )

const updateAffiliateSystem = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/tenant/affiliate-details`, data)

const getAllCms = ({ pageNo, limit, tenantId, search, adminId, isActive }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/${checkIsTenant() ? 'tenant' : 'superadmin'}/cms?pageNo=${pageNo}&limit=${limit}&tenantId=${tenantId}&search=${search}&adminId=${adminId}&isActive=${isActive}`
  )

const getCmsByPageId = ({ cmsPageId }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/${checkIsTenant() ? 'tenant' : 'superadmin'}/cms/details?cmsPageId=${cmsPageId}`
  )

const getAllUsers = ({
  limit, pageNo, tenantId, search, adminId, kycStatus,
  affiliateSearch, dobStart, dobEnd, userId, orderBy, isTenant,
  sortBy, registrationDateStart, registrationDateEnd, isInternal, country, ftdFrom, ftdTo, usersType, restrictedPlayers
}) =>
  getRequest(
    `${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/all?limit=${limit}&pageNo=${pageNo}${!isTenant ? `&tenantId=${tenantId}` : ''}&search=${search}&adminId=${adminId}&kycStatus=${kycStatus}&affiliateName=${affiliateSearch}&dobStart=${dobStart}&dobEnd=${dobEnd}&userId=${userId}&orderBy=${orderBy}&sort=${sortBy}&registrationDateStart=${registrationDateStart}&registrationDateEnd=${registrationDateEnd}&isInternal=${isInternal}&countryCode=${country}&ftdFrom=${ftdFrom || ''}&ftdTo=${ftdTo || ''}&usersType=${usersType}&restrictedPlayers=${restrictedPlayers ? `[${restrictedPlayers}]` : ''}`
  )

const getAllCasinoProviders = ({ limit, pageNo, search, aggregatorId }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/${checkIsTenant() ? 'tenant' : 'superadmin'}/casino/providers?limit=${limit}&pageNo=${pageNo}&search=${search}&aggregatorId=${aggregatorId}`
  )

const getAllCasinoGames = ({ isTenant, bonusId, limit, pageNo, casinoCategoryId, search, isActive, tenantId, selectedProvider, freespins, countryCode, portalId = '', tenantIds = '[]' }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/casino/games?limit=${limit}&pageNo=${pageNo}&casinoCategoryId=${casinoCategoryId}&search=${search}&isActive=${isActive}&tenantId=${tenantId}&providerId=${selectedProvider}&freespins=${freespins}&bonusId=${bonusId}&countryCode=${countryCode || ''}&portalId=${portalId}&tenantIds=${tenantIds}`)

const getAllExcludedCasinoGames = ({ isTenant, limit, pageNo, search, selectedProvider, bannedGamesListId }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/banned-games/excluded-games?limit=${limit}&pageNo=${pageNo}&search=${search}&providerId=${selectedProvider}&bannedGamesListId=${bannedGamesListId}`)

const updateTenantAffiliate = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/tenant/affiliate/user`, data)

const updateTheme = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/superadmin/theme`, data)

const updateThemeTenant = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/tenant/theme-setting`, data)

const createTheme = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/superadmin/theme`, data)

const createTenantAdmin = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/superadmin/tenant/admin`, data)

const editTenant = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/superadmin/tenant`, data)

const getTenant = ({ tenantId }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/superadmin/tenant/details?tenantId=${tenantId}`
  )
const createCurrency = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/${checkIsTenant() ? 'tenant' : 'superadmin'}/currency`, data)

const editCurrency = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/superadmin/currency`, data)

const getCurrencyById = (currencyId) =>
  getRequest(
    `${REACT_APP_API_URL}/api/${checkIsTenant() ? 'tenant' : 'superadmin'}/currency/details?currencyId=${currencyId}`
  )

const getAdminRole = () =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/roles`)

const getAdmin = ({ adminId }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/superadmin/details?superAdminUserId=${adminId && adminId}`
  )
const getAllCredentials = () =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/tenant/credentials-keys`)

const createTenantCredentials = (data) =>
  postRequest(
    `${REACT_APP_API_URL}/api/superadmin/tenant/credential`,
    data
  )

const updateTenantCredentials = (data) =>
  putRequest(
    `${REACT_APP_API_URL}/api/superadmin/tenant/credential`,
    data
  )

const getUser = ({ userId, isTenant }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user?userId=${userId}`)

const getAllCredKeys = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/credential/all-keys`)

const createCredentials = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/tenant/credential`, data)

const getAllCreds = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/credential`)

const getCredByKey = ({ credKey }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/tenant/credential/by-key?key=${credKey}`
  )

const updateCreds = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/tenant/credential`, data)

const getTenantDetails = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/details`)

const getAllTenantUsers = ({
  limit, pageNo, search, isActive, affiliateId, kycStatus,
  affiliateSearch, dobStart, dobEnd, userId, orderBy,
  sortBy, registrationDateStart, registrationDateEnd, isInternal,
  country, ftdFrom, ftdTo, usersType
}) =>
  getRequest(
    `${REACT_APP_API_URL}/api/tenant/user/all?limit=${limit}&pageNo=${pageNo}&search=${search}&isActive=${isActive}&affiliateId=${affiliateId}&kycStatus=${kycStatus}&affiliateName=${affiliateSearch}&dobStart=${dobStart}&dobEnd=${dobEnd}&userId=${userId}&orderBy=${orderBy}&sort=${sortBy}&registrationDateStart=${registrationDateStart}&registrationDateEnd=${registrationDateEnd}&isInternal=${isInternal}&countryCode=${country}&ftdFrom=${ftdFrom || ''}&ftdTo=${ftdTo || ''}&usersType=${usersType}`
  )

const getUserFields = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/registration`)

const updateUserFields = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/tenant/registration`, data)

const getAdminRoles = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/admin/roles`)

const getAdminUsers = (limit, pageNo, sort, roleId) =>
  getRequest(
    `${REACT_APP_API_URL}/api/tenant/admin?orderBy=email&limit=${limit}&pageNo=${pageNo}&sort=${sort}&adminRoleId=${roleId}`
  )

const getAdminUserDetails = ({ adminUserId, isTenant, isAggregatorDetail = false }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/${isTenant ? 'tenant/admin/details' : 'superadmin/tenant/admin-details'}?adminUserId=${adminUserId}&isAggregatorDetail=${isAggregatorDetail}`
  )

const createAdminUser = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/tenant/admin`, data)

const createCms = ({ data }) =>
  postRequest(`${REACT_APP_API_URL}/api/${checkIsTenant() ? 'tenant' : 'superadmin'}/cms`, data)

const getCmsDetails = (cmsId) =>
  getRequest(
    `${REACT_APP_API_URL}/api/tenant/cms/details?cmsPageId=${cmsId}`
  )

const updateCms = (cmsData) =>
  putRequest(`${REACT_APP_API_URL}/api/${checkIsTenant() ? 'tenant' : 'superadmin'}/cms`, cmsData)

const getTenantRole = () =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/tenant/roles`)

const getTenantAdminRole = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/admin/roles`)

const getTenantWallet = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/wallet`)

const getSuperAdminWallet = () =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/wallets`)

const getTenantCasinoCategories = ({ limit, pageNo }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/tenant/casino/all-category?limit=${limit}&pageNo=${pageNo}`
  )

const getTenantGames = ({ limit, pageNo, casinoCategoryId, isActive, orderBy, sort, providerId, search }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/tenant/casino/category-games?limit=${limit}&pageNo=${pageNo}&casinoCategoryId=${casinoCategoryId}&isActive=${isActive}&orderBy=${orderBy}&sort=${sort}&providerId=${providerId}&search=${search}`
  )

const getMasterGames = ({ bonusId, limit, pageNo, search, casinoCategoryId, providerId, freespins, countryCode, tenantIds = '[]' }) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/casino/games?limit=${limit}&pageNo=${pageNo}&search=${search}&casinoCategoryId=${casinoCategoryId}&providerId=${providerId}&freespins=${freespins}&bonusId=${bonusId}&isActive=true&countryCode=${countryCode || ''}&tenantIds=${tenantIds}`)

const getTenantCasinoSubCategories = ({ limit, pageNo, categoryId, search, isActive, orderBy, sort }) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/casino/all-sub-category?limit=${limit}&pageNo=${pageNo}&gameCategoryId=${categoryId}&search=${search}&isActive=${isActive}&orderBy=${orderBy}&sort=${sort}`)

const createTenantCasinoCategory = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/tenant/casino/category`, data)

const addGamesToSubCategory = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/tenant/casino/category-game`, data)

const createTenantCasinoSubCategory = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/tenant/casino/sub-category`, data)

const updateTenantCasinoSubCategory = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/tenant/casino/sub-category`, data)

const updateSubCategoryReOrder = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/tenant/casino/order-sub-category`, data)

const updateCategoryReOrder = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/tenant/casino/order-category`, data)

const getTenantCasinoMenuById = ({ casinoMenuId }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/tenant/casino/menu?casinoMenuId=${casinoMenuId}`
  )

const updateTenantCasinoCategory = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/tenant/casino/category`, data)

const getAllTenantCasinoMenuItems = ({ limit, pageNo, type, isActive }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/tenant/menu-items?limit=${limit}&pageNo=${pageNo}&type=${type}&isActive=${isActive}`
  )

const getCountries = ({ limit, pageNo, name, isActive, kycMethod = '' }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/${checkIsTenant() ? 'tenant' : 'superadmin'}/country/list?limit=${limit}&pageNo=${pageNo}&name=${name}&kycMethod=${kycMethod}&isActive=${isActive}`
  )

const getRestrictedItems = ({ limit, pageNo, type, countryId, search }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/superadmin/country/restricted-items?limit=${limit}&pageNo=${pageNo}&type=${type}&countryId=${countryId}&search=${search}`
  )

const getUnRestrictedItems = ({ limit, pageNo, type, countryId, search }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/superadmin/country/unrestricted-items?limit=${limit}&pageNo=${pageNo}&type=${type}&countryId=${countryId}&search=${search}`
  )

const addRestrictedItems = (data) => putRequest(`${REACT_APP_API_URL}/api/superadmin/country/restricted`, data)

const updateSuperAdminUser = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/superadmin/`, data)

const createSuperAdminUser = (data) =>
  postRequest(
    `${REACT_APP_API_URL}/api/superadmin/`,
    data
  )

const createCasinoGame = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/superadmin/casino/game`, data)

const createCasinoProvider = (data) =>
  postRequest(
    `${REACT_APP_API_URL}/api/superadmin/casino/provider`,
    data
  )

const updateCasinoGame = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/superadmin/casino/game`, data)

const updateCasinoProvider = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/${checkIsTenant() ? 'tenant' : 'superadmin'}/casino/provider`, data)

const getSuperAdminDetails = () =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/details`)

const createTenantCasinoMenuItem = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/tenant/menu-item`, data)

const getAllWageringCasinoGames = ({ isTenant, providerId, search }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/casino/all-games?providerId=${providerId}&search=${search}`)

const getAllTenantCasinoTables = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/casino/tables`)

const updateTenantCasinoMenuItem = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/tenant/menu-item`, data)

const updateTenantAdminUser = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/tenant/admin`, data)

const updateTenantAdmin = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/superadmin/tenant/admin`, data)

const getAllSuperAdminTransactions = ({ isTenant, countryCode, limit, pageNo, search, startDate, endDate, currencyId, transactionType, tenantId, adminId, paymentProvider, paymentMethod, userId, status, transactionId, createdAtStartDate, createdAtEndDate, check, orderBy, sort = '' }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/transactions?limit=${limit}&pageNo=${pageNo}&actioneeType=${search}&status=${status}${check.updatedAt ? `&startDate=${startDate}&endDate=${endDate}` : ''}&currencyCode=${currencyId}&transactionType=${transactionType?.length ? transactionType : '[]'}&tenantId=${tenantId}&adminId=${adminId}&paymentProvider=${paymentProvider}&transactionId=${transactionId}&countryCode=${countryCode}&userId=${userId}${check.createdAt ? `&createdAtStartDate=${createdAtStartDate}&createdAtEndDate=${createdAtEndDate}` : ''}&paymentMethod=${paymentMethod}&orderBy=${orderBy}&sort=${sort}`)

const getSuperAdminAllTransactions = ({ isTenant, countryCode, limit, pageNo, search, startDate, endDate, currencyId, transactionType, tenantId, adminId, paymentProvider, transactionId, userId, status }) => {
  return (
    getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/transactions-all?limit=${limit}&pageNo=${pageNo}&startDate=${startDate}&endDate=${endDate}&actioneeType=${search}&status=${status}&transactionId=${transactionId}&currencyCode=${currencyId}&transactionType=${transactionType}&tenantId=${tenantId}&adminId=${adminId}&paymentProvider=${paymentProvider}&countryCode=${countryCode}&userId=${userId}`)
  )
}

const getBannedGamesDetails = ({ startDate, endDate, userId, tenantId, isTenant = false, orderBy = '', orderType = '', limit, pageNo, gameSearch, providers = '' }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/casino/banned-games?userId=${userId}&endDate=${endDate}&startDate=${startDate}&tenantId=${tenantId}&gameSearch=${gameSearch}&orderType=${orderType}&orderBy=${orderBy}&pageNo=${pageNo}&limit=${limit}&providers=${providers}`)

const getAllTenantTransactions = ({ orderBy, sort = '', countryCode, limit, pageNo, search, startDate, endDate, currencyId, transactionType, paymentProvider, paymentMethod, userId, status, transactionId, createdAtStartDate, createdAtEndDate, check }) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/transactions?limit=${limit}&pageNo=${pageNo}&status=${status}&actioneeType=${search}${check.updatedAt ? `&startDate=${startDate}&endDate=${endDate}` : ''}&transactionId=${transactionId}&currencyCode=${currencyId}&transactionType=${transactionType?.length ? transactionType : '[]'}&paymentProvider=${paymentProvider}&countryCode=${countryCode}&userId=${userId}${check.createdAt ? `&createdAtStartDate=${createdAtStartDate}&createdAtEndDate=${createdAtEndDate}` : ''}&paymentMethod=${paymentMethod}&orderBy=${orderBy}&sort=${sort}`)

const getAllBannedGames = ({ adminId, pageNo, limit, search, isTenant, tenantId, selectedProvider = '' }) => {
  return (
    isTenant
      ? getRequest(`${REACT_APP_API_URL}/api/tenant/banned-games?pageNo=${pageNo}&limit=${limit}&search=${search}&selectedProvider=${selectedProvider}`)
      : getRequest(`${REACT_APP_API_URL}/api/superadmin/banned-games?tenantId=${tenantId}&adminId=${adminId}&pageNo=${pageNo}&limit=${limit}&search=${search}&selectedProvider=${selectedProvider}`)
  )
}

const getAllBannedGamesListDetail = ({ adminId = '', pageNo, limit, search = '', isTenant, bannedGamesListId, providerId = '' }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/banned-games/details?adminId=${adminId}&providerId=${providerId}&pageNo=${pageNo}&limit=${limit}&search=${search}&bannedGamesListId=${bannedGamesListId}`)

const createBannedGames = (isTenant, data) =>
  postRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/banned-games`, data)

const updateBannedGames = (isTenant, data) =>
  putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/banned-games`, data)

const removeBannedGames = (data) =>
  deleteRequest(`${REACT_APP_API_URL}/api/${data.isTenant ? 'tenant' : 'superadmin'}/banned-games`, data)

const bannedGamesToggler = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/${data?.isTenant ? 'tenant' : 'superadmin'}/banned-games/tenant-list`, data)

const getTenantTransactionUsers = ({ type, email }) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/transaction-users?type=${type}&email=${email}`)

const getSuperAdminTransactionUsers = ({ email }) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/transaction-users?email=${email}`)

const getAllTenantCasinoTransactions = ({ limit, pageNo, startDate, endDate, currencyCode, status, transactionType, userId, gameSearch, orderBy = '', orderType = '', searchByUserId = '', amountType = '', providers, showTotalAmount }) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/casino/transactions?limit=${limit}&pageNo=${pageNo}&startDate=${startDate}&endDate=${endDate}&currencyCode=${currencyCode}&orderType=${orderType}&orderBy=${orderBy}&gameSearch=${gameSearch}&status=${status}&transactionType=${transactionType}&userId=${userId}&searchByUserId=${searchByUserId}&showTotalAmount=${showTotalAmount}&amountType=${amountType}&providers=${providers}`)

const getTenantCountries = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/all-country`)

const getSuperAdminAggregators = ({ limit, pageNo, search }) =>
  getRequest(`${REACT_APP_API_URL}/api/${checkIsTenant() ? 'tenant' : 'superadmin'}/casino/aggregators?limit=${limit}&pageNo=${pageNo}&search=${search}`)

const createSuperAdminAggregator = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/superadmin/casino/aggregators`, data)

const editSuperAdminAggregator = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/${checkIsTenant() ? 'tenant' : 'superadmin'}/casino/aggregators`, data)

const getSuperAdminGameCategory = ({ limit, pageNo, search, tenantId }) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/casino/all-category?limit=${limit}&pageNo=${pageNo}&search=${search}&tenantId=${tenantId}`)

const getSuperAdminGameSubCategory = ({ limit, pageNo, search, gameCategoryId, isActive, tenantId }) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/casino/all-sub-category?pageNo=${pageNo}&gameCategoryId=${gameCategoryId}&search=${search}&limit=${limit}&isActive=${isActive}&tenantId=${tenantId}`)

const deleteTenantCasinoGame = (data) =>
  deleteRequest(`${REACT_APP_API_URL}/api/tenant/casino/category-game`, data)

const updateTenantCategoryGame = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/tenant/casino/category-game`, data)

const deleteRestrictedItems = (data) =>
  deleteRequest(`${REACT_APP_API_URL}/api/superadmin/country/restricted-items`, data)

const getRestrictedCountries = ({ itemId, type, limit, pageNo }) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/country/restricted?itemId=${itemId}&type=${type}&limit=${limit}&pageNo=${pageNo}`)

const getUnRestrictedCountries = ({ itemId, type, limit, pageNo, search }) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/country/unrestricted?itemId=${itemId}&type=${type}&limit=${limit}&pageNo=${pageNo}&search=${search}`)

const deleteRestrictedCountries = (data) =>
  deleteRequest(`${REACT_APP_API_URL}/api/superadmin/country/restricted`, data)

const addRestrictedCountries = (data) => putRequest(`${REACT_APP_API_URL}/api/superadmin/country/restricted-items`, data)

const addDepositToOther = ({ data, isTenant }) =>
  putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/add-balance`, data)

const getSuperAdminCasinoTransactions = ({ limit, pageNo, startDate, endDate, currencyCode, transactionType, tenantId, status, searchByUserId, adminId, gameSearch, userId, orderBy = '', orderType = '', amountType = '', isTenant = false, providers, showTotalAmount }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/casino/transactions?limit=${limit}&pageNo=${pageNo}&startDate=${startDate}&endDate=${endDate}&gameSearch=${gameSearch}&currencyCode=${currencyCode}&transactionType=${transactionType?.length ? transactionType : '[]'}&tenantId=${tenantId}&status=${status}&adminId=${adminId}&userId=${userId}&orderBy=${orderBy}&orderType=${orderType}&searchByUserId=${searchByUserId}&showTotalAmount=${showTotalAmount}&amountType=${amountType}&providers=${providers}`)

const getAllClients = ({ orderBy, limit, pageNo, sort, search, status }) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/tenant/admin-users?orderBy=${orderBy}&limit=${limit}&pageNo=${pageNo}&sort=${sort}&search=${search}&status=${status}`)

const getAllPortals = ({ adminId }) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/tenant/list?adminId=${adminId}`)

const getTenantList = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/list`)

const updateSuperAdminCMS = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/${checkIsTenant() ? 'tenant' : 'superadmin'}/cms`, data)

const isDomainExist = ({ domain, tenantId }) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/check-domain?domain=${domain}&tenantId=${tenantId}`)

const tenantViewToggleStatus = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/tenant/admin/status`, data)

const superAdminViewToggleStatus = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/superadmin/status`, data)

const deleteTenantAdminCategory = (data) =>
  deleteRequest(`${REACT_APP_API_URL}/api/tenant/casino/category`, data)

const deleteTenantAdminSubCategory = (data) =>
  deleteRequest(`${REACT_APP_API_URL}/api/tenant/casino/sub-category`, data)

const getAllSAProviders = ({ limit, pageNo, search, tenantId, tenantIds = '[]', allData = false }) =>
  getRequest(`${REACT_APP_API_URL}/api/${checkIsTenant() ? 'tenant' : 'superadmin'}/casino/all-providers${checkIsTenant() ? `?limit=${limit}&pageNo=${pageNo}&search=${search}${allData ? `&tenantId=${tenantId}` : ''}` : `?tenantId=${tenantId}`}&tenantIds=${tenantIds}`)

const getAllTAProviders = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/casino/providers`)

const getGlobalRegistration = () =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/global-registration`)

const updateGlobalRegistration = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/superadmin/global-registration`, data)

const getSAConvertAmount = ({ currencyFields, currencyCode, tenantIds }) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/bonus/convert-amount?currencyFields=${safeStringify(currencyFields)}&currentCurrencyCode=${currencyCode}&tenantIds=${tenantIds}`)

const getAllBonus = ({ adminId, tenantId, limit, pageNo, bonusType, isActive, search, userId, reorder, bonusId, isTenant }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/bonus?adminId=${adminId}&tenantId=${tenantId}&limit=${limit}&pageNo=${pageNo}&search=${search}&isActive=${isActive}&bonusType=${bonusType}&userId=${userId}&reorder=${reorder}&bonusId=${bonusId}`)

const createBonus = (data, isTenant) =>
  postRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/bonus`, data)

const updateBonus = (data, isTenant) =>
  putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/bonus`, data)

const getBonus = ({ bonusId, isTenant, userBonusId }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/bonus/detail?bonusId=${bonusId}&userBonusId=${userBonusId}`)

const issueBonus = ({ data, isTenant }) =>
  postRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/bonus/issue`, data)

const getUserBonus = ({ limit, pageNo, bonusType, status, userId, isTenant, bonusId }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/bonus/user?limit=${limit}&pageNo=${pageNo}&bonusType=${bonusType}&status=${status}&userId=${userId}&bonusId=${bonusId}`)

const cancelBonus = ({ data, isTenant }) =>
  putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/bonus/cancel`, data)

const getUserDocument = (userId, flag) =>
  getRequest(`${REACT_APP_API_URL}/api/${flag}/user/document?userId=${userId}`)

const verifyUserDocument = (data, isTenant) =>
  putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/verify-document`, data)

const getDocumentLabel = (userId, isTenant) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/document-label?userId=${userId}`)

const getDemographicDetailsTenant = ({ endDate, startDate, dateOptions }) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/report/demographic?startDate=${startDate}&endDate=${endDate}&dateOptions=${dateOptions}`)

const getDemographicDetailsSuperAdmin = ({ endDate, startDate, adminId, tenantId, dateOptions }) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/report/demographic?startDate=${startDate}&endDate=${endDate}&adminId=${adminId}&tenantId=${tenantId}&dateOptions=${dateOptions}`)

const updateDocumentLabel = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/superadmin/document-label`, data)

const createDocumentLabel = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/superadmin/document-label`, data)

const requestDocument = (data, isTenant) =>
  putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/request-document`, data)

const cancelDocumentRequest = (data, isTenant) =>
  putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/cancel-document-request`, data)

const getTenantPlayerManagement = ({ endDate, startDate, limit, dateOptions }) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/report/top-players?startDate=${startDate}&endDate=${endDate}&limit=${limit}&dateOptions=${dateOptions}`)

const getSuperAdminPlayerManagement = ({ endDate, startDate, adminId, tenantId, limit, dateOptions }) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/report/top-players?startDate=${startDate}&endDate=${endDate}&adminId=${adminId}&tenantId=${tenantId}&limit=${limit}&dateOptions=${dateOptions}`)

const getPaymentMethod = ({ flag }) =>
  getRequest(`${REACT_APP_API_URL}/api/${flag}/bonus/payment-methods`)

const getWageringTemplateDetail = ({ wageringTemplateId }) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/wagering-template/details?wageringTemplateId=${wageringTemplateId}`)
const getTenantLivePlayerReport = ({ limit }) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/report/live-player`)

const getSuperAdminLivePlayerReport = ({ adminId, tenantId, limit }) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/report/live-player?&adminId=${adminId}&tenantId=${tenantId}`)

const getSuperAdminAllWageringTemplate = ({ adminId }) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/wagering-template/all?adminId=${adminId}`)

const getTenantAllWageringTemplate = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/wagering-template/all`)

const getSuperAdminWageringTemplateDetail = ({ wageringTemplateId, limit, pageNo, providerId, search }) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/wagering-template/details?wageringTemplateId=${wageringTemplateId}&pageNo=${pageNo}&limit=${limit}&providerId=${providerId}&search=${search}`)

const getTenantWageringTemplateDetail = ({ wageringTemplateId, limit, pageNo, providerId, search }) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/wagering-template/details?wageringTemplateId=${wageringTemplateId}&pageNo=${pageNo}&limit=${limit}&providerId=${providerId}&search=${search}`)

const createWageringTemplate = (isTenant, data) =>
  postRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/wagering-template`, data)

const updateWageringTemplate = (isTenant, data) =>
  putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/wagering-template`, data)
const getBalanceBonuses = ({ tenantId }) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/bonus/balance?tenantId=${tenantId}`)
const getTABalanceBonuses = ({ tenantId }) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/bonus/balance?tenantId=${tenantId}`)

const getTAConvertAmount = ({ currencyFields, currencyCode, tenantIds }) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/bonus/convert-amount?currencyFields=${safeStringify(currencyFields)}&currentCurrencyCode=${currencyCode}&tenantIds=${tenantIds}`)

const getAllTABonus = ({ limit, pageNo, bonusType, isActive, search, userId, reorder, bonusId }) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/bonus?limit=${limit}&pageNo=${pageNo}&search=${search}&isActive=${isActive}&bonusType=${bonusType}&userId=${userId}&reorder=${reorder}&bonusId=${bonusId}`)

const getTAWageringTemplateDetail = ({ wageringTemplateId, limit, providerId, pageNo, search }) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/wagering-template/details?wageringTemplateId=${wageringTemplateId}&providerId=${providerId}&limit=${limit}&pageNo=${pageNo}&search=${search}`)

const getSuperAdminWageringTemplate = ({ adminId, search, limit, pageNo }) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/wagering-template?adminId=${adminId}&pageNo=${pageNo}&limit=${limit}&search=${search}`)

const getTenantWageringTemplate = ({ search, limit, pageNo }) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/wagering-template?pageNo=${pageNo}&limit=${limit}&search=${search}`)

const getSAPlayerLiability = ({ tenantId, adminId, startDate, endDate }) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/report/players-liability?tenantId=${tenantId}&adminId=${adminId}&startDate=${startDate}&endDate=${endDate}`)

const getTAPlayerLiability = ({ tenantId, startDate, endDate }) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/report/players-liability?tenantId=${tenantId}&startDate=${startDate}&endDate=${endDate}`)

const getSAKPISummary = ({ tenantId, adminId, startDate, endDate }) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/report/kpi-summary?tenantId=${tenantId}&adminId=${adminId}&startDate=${startDate}&endDate=${endDate}`)

const getTAKPISummary = ({ tenantId, startDate, endDate }) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/report/kpi-summary?tenantId=${tenantId}&startDate=${startDate}&endDate=${endDate}`)

const getSAKPIReport = ({ tenantId, adminId, startDate, endDate, dateOptions, selectedTab }) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/report/kpi?tab=${selectedTab}&tenantId=${tenantId}&adminId=${adminId}&customStartDate=${startDate}&customEndDate=${endDate}&dateOptions=${dateOptions}`)

const getTAKPIReport = ({ tenantId, startDate, endDate, dateOptions, selectedTab }) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/report/kpi?tab=${selectedTab}&tenantId=${tenantId}&customStartDate=${startDate}&customEndDate=${endDate}&dateOptions=${dateOptions}`)

const getTenantOwnerDetails = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/admin/details?isAggregatorDetail=true`)

const getSAGameReport = ({ tenantId, adminId, startDate, endDate, dateOptions, selectedTab, orderBy, sort }) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/report/game?tenantId=${tenantId}&adminId=${adminId}&customStartDate=${startDate}&customEndDate=${endDate}&dateOptions=${dateOptions}&tab=${selectedTab}&orderBy=${orderBy}&sort=${sort}`)

const getTAGameReport = ({ tenantId, startDate, endDate, dateOptions, selectedTab, orderBy, sort }) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/report/game?tenantId=${tenantId}&customStartDate=${startDate}&customEndDate=${endDate}&dateOptions=${dateOptions}&tab=${selectedTab}&orderBy=${orderBy}&sort=${sort}`)

const getSAPlayerGameReport = ({ userId, limit, startDate, endDate, dateOptions, selectedTab }) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/report/game?userId=${userId}&limit=${limit}&customStartDate=${startDate}&customEndDate=${endDate}&dateOptions=${dateOptions}&tab=${selectedTab}`)

const getTAPlayerGameReport = ({ userId, limit, startDate, endDate, dateOptions, selectedTab }) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/report/game?userId=${userId}&limit=${limit}&customStartDate=${startDate}&customEndDate=${endDate}&dateOptions=${dateOptions}&tab=${selectedTab}`)

const getCurrencyWiseWithdrawals = ({ isTenant, startDate, endDate, tenantId, adminId = '' }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/report/currency-wise-withdrawals?tenantId=${tenantId}&adminId=${adminId}&startDate=${startDate}&endDate=${endDate}`)

const getJackpotContribution = ({ isTenant, startDate, endDate, tenantId, adminId = '', selectedTab, userId }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/report/jackpot?tenantId=${tenantId}&adminId=${adminId}&startDate=${startDate}&endDate=${endDate}&tab=${selectedTab}&userId=${userId}`)

const getFreeSpinGames = ({ providerId, bonusId, limit, pageNo, search, isTenant, tenantIds = '[]' }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/casino/freespin-games?providerId=${providerId}&bonusId=${bonusId}&limit=${limit}&pageNo=${pageNo}&search=${search}&tenantIds=${tenantIds}`)

const getEmailTemplates = ({ isTenant }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/email/all`)

const getEmailTemplate = ({ emailTemplateId, isTenant, tenantId }) =>
  tenantId
    ? getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/email?emailTemplateId=${emailTemplateId}&tenantId=${tenantId}`)
    : getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/email?emailTemplateId=${emailTemplateId}`)

const updateEmailTemplate = ({ data, isTenant }) =>
  putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/email`, data)

const primaryEmailTemplate = ({ data }) =>
  postRequest(`${REACT_APP_API_URL}/api/tenant/email/mark-primary`, data)

const getDynamicKeys = ({ isTenant, type }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/email/dynamic-data?type=${type}`)

const createEmailTemplate = ({ data, isTenant }) =>
  postRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/email`, data)

const getloyaltyLevel = ({ isTenant, tenantId }) =>
  isTenant
    ? tenantId
      ? getRequest(`${REACT_APP_API_URL}/api/tenant/bonus/loyalty-level?tenantId=${tenantId}`)
      : getRequest(`${REACT_APP_API_URL}/api/tenant/bonus/loyalty-level`)
    : getRequest(`${REACT_APP_API_URL}/api/superadmin/bonus/loyalty-level?tenantId=${tenantId}`)

const updateloyaltyLevel = ({ isTenant, data }) =>
  putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/bonus/loyalty-level`, data)

const updateLoyaltyPoint = ({ loyaltyPoint }) =>
  putRequest(`${REACT_APP_API_URL}/api/tenant/loyalty-point`, loyaltyPoint)

const updateLoyaltyLimit = ({ loyaltyLimit }) =>
  putRequest(`${REACT_APP_API_URL}/api/tenant/limit`, loyaltyLimit)

const getLanguages = ({ limit, pageNo, name }) =>
  getRequest(`${REACT_APP_API_URL}/api/${checkIsTenant() ? 'tenant' : 'superadmin'}/language?limit=${limit}&pageNo=${pageNo}&name=${name}`)

const updateProfile = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/superadmin/profile`, data)

const updateAdminProfile = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/tenant/admin/profile`, data)

const getAdminChildren = ({ superAdminId, superRoleId }) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/childs?superAdminId=${superAdminId}&superRoleId=${superRoleId}`)

const getAllGroups = (isTenant) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/all-group`)

const getTags = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/user/tags`)

const updateTags = ({ data, isTenant }) =>
  putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/tags`, data)

const getImageGallery = (isTenant, tenantId) =>
  getRequest(`${REACT_APP_API_URL}/api/${checkIsTenant() ? 'tenant' : 'superadmin'}/gallery?tenantId=${tenantId}`)

const deleteFromGallery = ({ isTenant, data }) =>
  deleteRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/gallery`, data)

const getDuplicateUsers = ({ limit, pageNo, userId, isTenant }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/duplicate?limit=${limit}&pageNo=${pageNo}&userId=${userId}`)

const testEmailTemplateEndPoint = ({ data, isTenant }) =>
  postRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/email/test`, data)

const setDailyLimit = ({ isTenant, data }) =>
  postRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/daily-limit`, data)

const setDepositLimit = ({ isTenant, data }) =>
  postRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/deposit-limit`, data)

const setLossLimit = ({ isTenant, data }) =>
  postRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/loss-limit`, data)

const getOwnerPermissions = () =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/tenant/owner-permission`)

const setEmailCreds = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/superadmin/email/credentials`, data)

const disableUser = ({ isTenant, data }) =>
  postRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/disable-until`, data)

const setSessionLimit = ({ isTenant, data }) =>
  postRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/session-time`, data)

const getBanners = ({ tenantId }) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/banner?tenantId=${tenantId}`)

const uploadBanner = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/tenant/banner`, data)

const getCMSDynamicKeys = () =>
  getRequest(`${REACT_APP_API_URL}/api/${checkIsTenant() ? 'tenant' : 'superadmin'}/cms/dynamic-data`)

const getAllSABanners = ({ adminId, tenantId }) =>
  getRequest(`${REACT_APP_API_URL}/api/${checkIsTenant() ? 'tenant' : 'superadmin'}/banner?adminUserId=${adminId}&tenantId=${tenantId}`)

const uploadSABanner = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/superadmin/banner`, data)

const updateSABanner = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/${checkIsTenant() ? 'tenant' : 'superadmin'}/banner`, data)

const deleteEmailTemplateLanguage = ({ isTenant, data }) =>
  deleteRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/email/language`, data)

const getEmailTypes = ({ isTenant }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/email/dynamic-data`)

const deleteCMSLanguage = ({ data }) =>
  deleteRequest(`${REACT_APP_API_URL}/api/${checkIsTenant() ? 'tenant' : 'superadmin'}/cms`, data)

const updateReorderGames = ({ data }) =>
  putRequest(`${REACT_APP_API_URL}/api/tenant/casino/order-category-games`, data)

const getTenantLanguages = ({ isTenant, tenantId }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin/tenant'}/language?tenantId=${tenantId}`)

const addInternalTag = ({ data, isTenant }) =>
  putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/internal`, data)

const getLanguageSupportKeys = ({ tenantId, language, isTenant, errorCodes }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/language/support-keys?tenantId=${tenantId}&language=${language}&errorCodes=${errorCodes}`)

const uploadLanguageCSV = ({ data, isTenant }) =>
  postRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/language/load-file`, data)

const updateLanguageSupport = ({ data, isTenant }) =>
  putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/language/support-keys`, data)

const getCMSLanguage = ({ adminId, tenantId }) =>
  getRequest(`${REACT_APP_API_URL}/api/${checkIsTenant() ? 'tenant' : 'superadmin'}/cms/language?adminUserId=${adminId}&tenantId=${tenantId}`)

const getElasticHealth = () =>
  getRequest(`${REACT_APP_API_URL}/api/${checkIsTenant() ? 'tenant' : 'superadmin'}/report/elastic-health-check`)

const sendMail = ({ email, isSuperAdmin }) =>
  putRequest(`${REACT_APP_API_URL}/api/${isSuperAdmin ? 'superadmin' : 'tenant/admin'}/forget-password`, { email })

const resetPassword = ({ isSuperAdmin, data }) =>
  putRequest(`${REACT_APP_API_URL}/api/${isSuperAdmin ? 'superadmin' : 'tenant/admin'}/verify-forget-password`, data)

const updateUserAffiliate = ({ isSuperAdmin, data }) =>
  putRequest(`${REACT_APP_API_URL}/api/${isSuperAdmin ? 'superadmin' : 'tenant'}/affiliate/user`, data)

const addUserAffiliate = ({ isSuperAdmin, data }) =>
  putRequest(`${REACT_APP_API_URL}/api/${isSuperAdmin ? 'superadmin' : 'tenant'}/affiliate/add-user`, data)

const getPaymentAggregators = ({ isTenant, tenantId }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin/payment'}/aggregators?tenantId=${tenantId}`)

const getPaymentProvider = ({ isTenant, paymentType, aggregator, tenant, group, status, tenantIds, category, adminId, tenantId, reorder, userId, search }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/payment/providers?paymentType=${paymentType}&aggregator=${aggregator}&tenant=${tenant}&group=${group}&status=${status}&tenantIds=${tenantIds}&category=${category}&adminId=${adminId}&tenantId=${tenantId}&reorder=${reorder}&userId=${userId}&search=${search}`)

const getProviderDetails = ({ providerId, isTenant, tenantId, adminId }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/payment/provider-details?providerId=${providerId}&tenantId=${tenantId}&adminId=${adminId}`)

const configProviderSettings = ({ data, isTenant }) =>
  putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/payment/provider-settings`, data)

const updateKycMethod = ({ data }) =>
  putRequest(`${REACT_APP_API_URL}/api/${checkIsTenant() ? 'tenant' : 'superadmin'}/country/${checkIsTenant() ? 'method' : 'kyc-method'}`, data)

const updatePaymentProviderStatus = ({ data, isTenant }) =>
  putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/update-provider-status`, data)

const reOrderPaymentProviders = ({ data, isTenant }) =>
  putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/payment/order-providers`, data)

const getCommentsList = ({ limit, search, pageNo, userId, isTenant, role, status }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/comments?limit=${limit}&pageNo=${pageNo}&search=${search}&userId=${userId}&role=${role}&status=${status}`)

const addComments = ({ isTenant, data }) =>
  postRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/comment`, data)

const updateSAUserStatus = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/superadmin/status`, data)

const updateComment = ({ data, isTenant }) =>
  putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/comment-status`, data)
const reorderBonus = ({ data, isTenant }) =>
  putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/bonus/order`, data)

const getSegments = ({ isTenant, tenantId = '' }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/bonus/segments?tenantId=${tenantId}`)

const deleteBonus = ({ isTenant, data }) =>
  deleteRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/bonus`, data)

const verifyPlayerEmail = ({ isTenant, data }) =>
  putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/verify-email`, data)

const verifyPlayerPhone = ({ data }) =>
  putRequest(`${REACT_APP_API_URL}/api/${checkIsTenant() ? 'tenant' : 'superadmin'}/user/verify-phone`, data)

const getAllReviews = ({ limit, search, pageNo, isTenant, tenantId, status }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? `tenant/review?limit=${limit}&pageNo=${pageNo}&search=${search}&status=${status}` : `superadmin/tenant/review?limit=${limit}&pageNo=${pageNo}&search=${search}&status=${status}&tenantId=${tenantId}`}`)

const addReview = ({ data }) =>
  postRequest(`${REACT_APP_API_URL}/api/superadmin/tenant/review`, data)

const updateReview = ({ data }) =>
  putRequest(`${REACT_APP_API_URL}/api/superadmin/tenant/review`, data)

const resetProfilePassword = ({ isTenant, data }) =>
  putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant/admin' : 'superadmin'}/change-password`, data)

const resetPasswordEmail = ({ isTenant, data }) =>
  putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/reset-password`, data)

const resetUserPassword = ({ isTenant, data }) =>
  putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/update-password`, data)

const getPaymentCategories = ({ paymentType, isTenant, adminId, tenantId }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/payment/category?paymentType=${paymentType}&adminId=${adminId}&tenantId=${tenantId}`)

const orderPaymentCategories = ({ data, isTenant }) =>
  putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/payment/order-category`, data)

const updateUser = ({ data, isTenant }) =>
  putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user`, data)

const getCashbackData = ({ isTenant, userId, level, tenantId, currencyCode }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/cashback?userId=${userId}&level=${level}&tenantId=${tenantId}&currencyCode=${currencyCode}`)

const getCurrentCashbackData = ({ isTenant, userId, level, tenantId, currencyCode }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/current-cashback?userId=${userId}&level=${level}&tenantId=${tenantId}&currencyCode=${currencyCode}`)

const sendUserEmail = ({ data, isTenant }) =>
  postRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/send-mail`, data)

const getEmailLogs = ({ isTenant, limit, pageNo, search, role, userId }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/email-logs?limit=${limit}&pageNo=${pageNo}&search=${search}&role=${role}&userId=${userId}`)

const getOwnerManagers = ({ ownerId, limit, pageNo }) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/owner-admins?ownerId=${ownerId}&limit=${limit}&pageNo=${pageNo}`)

const getWalletData = ({ userId, isTenant, tenantId }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/wallet-details?userId=${userId}&tenantId=${tenantId}`)

const getKycLabels = ({ userId, type, tenantId = '' }) =>
  getRequest(`${REACT_APP_API_URL}/api/${checkIsTenant() ? 'tenant' : 'superadmin'}/user/kyc-labels?userId=${userId}&type=${type}&tenantId=${tenantId}`)

const updateKycLabels = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/${checkIsTenant() ? 'tenant' : 'superadmin'}/user/kyc-labels`, data)

const getQrCode = ({ userType, isTenant }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/generate-otp-2fa?userType=${userType}`)

const verifyAuthCode = ({ token, isTenant }) =>
  postRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/verify-otp-2fa`, { token })

const disableAuthenticator = ({ isTenant }) =>
  postRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/disable-auth`)

const updateUserCurrPermission = ({ userId, isTenant }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/check-currency-editable?userId=${userId}`)

const getUserStatus = ({ userId, isTenant }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/current-status?userId=${userId}`)

const getTransactionPaymentCategories = ({ isTenant, adminId, tenantId }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/transaction-providers?adminId=${adminId}&tenantId=${tenantId}`)

const getTransactionReportingPaymentCategories = ({ isTenant, adminId, tenantId }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/provider-reporting-names?adminId=${adminId}&tenantId=${tenantId}`)

const updateTransactionStatus = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/${data.isTenant ? 'tenant' : 'superadmin'}/user/transaction`, data)

const updateErrorCodes = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/superadmin/payment/errors`, data)

const getCasinoThemes = ({ isTenant }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/casino/all-themes`)

const getCasinoGameIds = ({ search, rating, provider, category, themes, tenantId, country, isTenant }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/casino/filtered-games?search=${search}&rating=${rating}&themes=${themes}&provider=${provider}&deviceType=desktop&tenantId=${tenantId}&country=${country}&category=${category}`)

const managePayment = ({ data, isTenant }) =>
  postRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/transaction`, data)

const getGroups = ({ isTenant, groupName, limit, pageNo }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/group-permission/all?groupName=${groupName}&limit=${limit}&pageNo=${pageNo}`)

const getGroupDetails = ({ groupId, isTenant, groupName }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/group-permission?groupId=${groupId}&groupName=${groupName}`)

const updateGroup = ({ data, isTenant }) =>
  putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/group-permission`, data)

const createGroup = ({ data, isTenant }) =>
  postRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/group-permission`, data)

const getTournaments = ({ isTenant }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/tournament`)

const getTournamentDetails = ({ tournamentId, isTenant }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/tournament/details?tournamentId=${tournamentId}`)

const createTournament = ({ data, isTenant }) =>
  postRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/tournament`, data)

const updateTournament = ({ data, isTenant }) =>
  putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/tournament`, data)

const auditLogsDetails = ({ search, isTenant, userId, createdStartDate, createdEndDate, limit, pageNo }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/action-trail?userId=${userId}&createdStartDate=${createdStartDate}&createdEndDate=${createdEndDate}&limit=${limit}&pageNo=${pageNo}&search=${search}`)

const userSessionLogs = ({ search, isTenant, userId, createdStartDate, createdEndDate, limit, pageNo }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/session?userId=${userId}&createdStartDate=${createdStartDate}&createdEndDate=${createdEndDate}&limit=${limit}&pageNo=${pageNo}&search=${search}`)

const addPaymentMethod = ({ data, isTenant }) =>
  postRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/payment/providers`, data)

const getPaymentGroups = ({ isTenant }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/payment/groups`)

const deleteTournament = ({ data, isTenant }) =>
  deleteRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/tournament`, data)

const getUserAffiliateNewData = ({ isTenant, userId }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/affiliate/user-data?userId=${userId}`)

const getUserAffiliateOldData = ({ isTenant, userId, startDate, endDate }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/affiliate/user?userId=${userId}&startDate=${startDate}&endDate=${endDate}`)

const updateUserAffiliateData = ({ data, isTenant }) =>
  putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/affiliate/user-data`, data)

const restrictPlayerMethods = ({ data, isTenant }) =>
  putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/payment/restrict-player`, data)

const getRGPlayers = ({ isTenant, page, limit, tenantId, selectedTab, search, userId, selfExcluded }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? `tenant/rg/all?pageNo=${page}` : `superadmin/rg/all?pageNo=${page}&tenantId=${tenantId}`}&limit=${limit}&selectedTab=${selectedTab}&search=${search}&userId=${userId}&selfExcluded=${selfExcluded}`)

const getRGThreshold = ({ isTenant, tenantId }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant/rg' : `superadmin/rg?tenantId=${tenantId}`}`)

const setRGThreshold = ({ isTenant, data }) =>
  putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/rg/threshold`, data)

const getAmlPlayers = ({ isTenant, page, limit, tenantId, selectedTab, search, userId }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? `tenant/aml/players?pageNo=${page}` : `superadmin/aml/players?pageNo=${page}&tenantId=${tenantId}`}&limit=${limit}&reportType=${selectedTab}&search=${search}&userId=${userId}`)

const getAmlThreshold = ({ isTenant, tenantId }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant/aml/settings' : `superadmin/aml/settings?tenantId=${tenantId}`}`)

const setAmlThreshold = ({ isTenant, data }) =>
  putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/aml/settings`, data)

const getExcludedPortalDetails = ({ email, parentId }) =>
  getRequest(`${REACT_APP_API_URL}/api/${checkIsTenant() ? 'tenant' : 'superadmin'}/rg/portals?email=${email}&parentId=${parentId}`)

const getRealityCheckData = (adminId) =>
  getRequest(`${REACT_APP_API_URL}/api/${checkIsTenant() ? 'tenant' : 'superadmin'}/reality-check?adminId=${adminId}`)

const setRealityCheckData = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/${checkIsTenant() ? 'tenant' : 'superadmin'}/reality-check`, data)

const setGameLimit = ({ data }) =>
  putRequest(`${REACT_APP_API_URL}/api/${checkIsTenant() ? 'tenant' : 'superadmin'}/user/set-game-play-limit`, data)

const getTALanguages = () =>
  getRequest(`${REACT_APP_API_URL}/api/${checkIsTenant() ? 'tenant' : 'superadmin'}/language/all`)

const setTALanguages = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/${checkIsTenant() ? 'tenant' : 'superadmin'}/language`, data)

const addAggregators = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/${checkIsTenant() ? 'tenant' : 'superadmin'}/add-tenant-games`, data?.data)

const updateCountryStatus = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/${checkIsTenant() ? 'tenant' : 'superadmin'}/country/status`, data)

export {
  addReview,
  getAllReviews,
  updateReview,
  superAdminLogin,
  tenantLogin,
  getAllTenants,
  getAllAdmins,
  createTenant,
  getThemeData,
  getAllThemes,
  getAllCurrencies,
  getAllTenantAffiliates,
  getAllTenantAdmins,
  getAllTenantCMS,
  createCurrency,
  editCurrency,
  getAllAffiliates,
  getAllCms,
  getAllUsers,
  createTenantAdmin,
  getTenant,
  editTenant,
  getAdminRole,
  getAdmin,
  getCurrencyById,
  getUser,
  getAllCredKeys,
  updateTheme,
  createTheme,
  getAllCredentials,
  createCredentials,
  getAllCreds,
  getCredByKey,
  updateCreds,
  getTenantDetails,
  getAllTenantUsers,
  createTenantCredentials,
  getUserFields,
  updateUserFields,
  updateTenantCredentials,
  getAdminRoles,
  getAdminUsers,
  getAdminUserDetails,
  createAdminUser,
  getAllThemesTenant,
  updateThemeTenant,
  getTenantAffiliateById,
  updateTenantAffiliate,
  createCms,
  getCmsDetails,
  updateCms,
  getTenantRole,
  getTenantAdminRole,
  getTenantWallet,
  getSuperAdminWallet,
  getAffiliateById,
  getCmsByPageId,
  getTenantCasinoCategories,
  getTenantCasinoSubCategories,
  createTenantCasinoCategory,
  getTenantCasinoMenuById,
  updateTenantCasinoCategory,
  getAllCasinoProviders,
  getAllCasinoGames,
  getCountries,
  createSuperAdminUser,
  updateSuperAdminUser,
  getAllTenantCasinoMenuItems,
  getSuperAdminDetails,
  createCasinoGame,
  createCasinoProvider,
  updateCasinoGame,
  updateCasinoProvider,
  createTenantCasinoMenuItem,
  getAllWageringCasinoGames,
  getAllTenantCasinoTables,
  updateTenantCasinoMenuItem,
  updateTenantAdminUser,
  updateTenantAdmin,
  getAllSuperAdminTransactions,
  getAllTenantTransactions,
  getAllTenantCasinoTransactions,
  getTenantTransactionUsers,
  getSuperAdminTransactionUsers,
  getTenantCountries,
  getSuperAdminAggregators,
  createSuperAdminAggregator,
  getSuperAdminGameCategory,
  getSuperAdminGameSubCategory,
  createTenantCasinoSubCategory,
  updateTenantCasinoSubCategory,
  getTenantGames,
  getMasterGames,
  addGamesToSubCategory,
  deleteTenantCasinoGame,
  updateTenantCategoryGame,
  updateSubCategoryReOrder,
  updateCategoryReOrder,
  getRestrictedItems,
  getUnRestrictedItems,
  addRestrictedItems,
  deleteRestrictedItems,
  getRestrictedCountries,
  getUnRestrictedCountries,
  deleteRestrictedCountries,
  addRestrictedCountries,
  getAllTenantCurrencies,
  addDepositToOther,
  getSuperAdminCasinoTransactions,
  getAllClients,
  getAllPortals,
  getTenantList,
  isDomainExist,
  updateSuperAdminCMS,
  superAdminViewToggleStatus,
  tenantViewToggleStatus,
  deleteTenantAdminCategory,
  deleteTenantAdminSubCategory,
  getAllSAProviders,
  getAllTAProviders,
  getGlobalRegistration,
  updateGlobalRegistration,
  getSAConvertAmount,
  getAllBonus,
  createBonus,
  updateBonus,
  getBonus,
  issueBonus,
  getUserBonus,
  cancelBonus,
  getUserDocument,
  verifyUserDocument,
  getDocumentLabel,
  updateDocumentLabel,
  getDemographicDetailsTenant,
  getDemographicDetailsSuperAdmin,
  createDocumentLabel,
  requestDocument,
  cancelDocumentRequest,
  getPaymentMethod,
  getWageringTemplateDetail,
  getSuperAdminPlayerManagement,
  getTenantPlayerManagement,
  getSuperAdminLivePlayerReport,
  getTenantLivePlayerReport,
  getSuperAdminAllWageringTemplate,
  getTenantAllWageringTemplate,
  getSuperAdminWageringTemplateDetail,
  createWageringTemplate,
  updateWageringTemplate,
  getBalanceBonuses,
  getTAConvertAmount,
  getAllTABonus,
  getTAWageringTemplateDetail,
  getSuperAdminWageringTemplate,
  getTenantWageringTemplate,
  getTenantWageringTemplateDetail,
  getTABalanceBonuses,
  getSAPlayerLiability,
  getTAPlayerLiability,
  getSAKPISummary,
  getTAKPISummary,
  getSAKPIReport,
  getTAKPIReport,
  getSAGameReport,
  getTAGameReport,
  getSAPlayerGameReport,
  getTAPlayerGameReport,
  getFreeSpinGames,
  getTenantOwnerDetails,
  getloyaltyLevel,
  updateloyaltyLevel,
  getLanguages,
  getEmailTemplates,
  getEmailTemplate,
  primaryEmailTemplate,
  updateEmailTemplate,
  createEmailTemplate,
  updateLoyaltyPoint,
  updateLoyaltyLimit,
  getDynamicKeys,
  getAdminChildren,
  updateProfile,
  updateAdminProfile,
  getAllGroups,
  getTags,
  updateTags,
  getImageGallery,
  deleteFromGallery,
  getDuplicateUsers,
  testEmailTemplateEndPoint,
  setDailyLimit,
  setDepositLimit,
  setLossLimit,
  getOwnerPermissions,
  setEmailCreds,
  disableUser,
  setSessionLimit,
  getBanners,
  uploadBanner,
  getCMSDynamicKeys,
  deleteEmailTemplateLanguage,
  getEmailTypes,
  getAllSABanners,
  uploadSABanner,
  updateSABanner,
  deleteCMSLanguage,
  updateReorderGames,
  getTenantLanguages,
  addInternalTag,
  getLanguageSupportKeys,
  uploadLanguageCSV,
  updateLanguageSupport,
  getCMSLanguage,
  getElasticHealth,
  sendMail,
  resetPassword,
  updateUserAffiliate,
  addUserAffiliate,
  updateKycMethod,
  getPaymentAggregators,
  getPaymentProvider,
  getProviderDetails,
  configProviderSettings,
  updatePaymentProviderStatus,
  reOrderPaymentProviders,
  getCommentsList,
  addComments,
  updateSAUserStatus,
  updateComment,
  reorderBonus,
  getSegments,
  deleteBonus,
  verifyPlayerEmail,
  resetProfilePassword,
  resetPasswordEmail,
  resetUserPassword,
  getPaymentCategories,
  orderPaymentCategories,
  updateUser,
  getCashbackData,
  sendUserEmail,
  getEmailLogs,
  getOwnerManagers,
  getWalletData,
  getQrCode,
  verifyAuthCode,
  disableAuthenticator,
  updateUserCurrPermission,
  getUserStatus,
  getTransactionPaymentCategories,
  updateTransactionStatus,
  getSuperAdminAllTransactions,
  updateErrorCodes,
  getBannedGamesDetails,
  getCasinoThemes,
  getCasinoGameIds,
  getCurrentCashbackData,
  getTransactionReportingPaymentCategories,
  managePayment,
  getAllBannedGames,
  createBannedGames,
  getAllBannedGamesListDetail,
  updateBannedGames,
  getAllExcludedCasinoGames,
  removeBannedGames,
  getGroups,
  getGroupDetails,
  createGroup,
  updateGroup,
  auditLogsDetails,
  getCurrencyWiseWithdrawals,
  bannedGamesToggler,
  addPaymentMethod,
  getPaymentGroups,
  getTournaments,
  getTournamentDetails,
  createTournament,
  updateTournament,
  deleteTournament,
  getKycLabels,
  updateKycLabels,
  getUserAffiliateOldData,
  getUserAffiliateNewData,
  updateUserAffiliateData,
  restrictPlayerMethods,
  getRGPlayers,
  getRGThreshold,
  setRGThreshold,
  getAmlPlayers,
  getAmlThreshold,
  setAmlThreshold,
  getExcludedPortalDetails,
  editSuperAdminAggregator,
  getRealityCheckData,
  setRealityCheckData,
  setGameLimit,
  getJackpotContribution,
  userSessionLogs,
  getTALanguages,
  setTALanguages,
  updateAffiliateSystem,
  addAggregators,
  updateCountryStatus,
  verifyPlayerPhone
}
