import { useDispatch, useSelector } from 'react-redux'
import { getGroupDetailStart, getSuperAdminDetailsStart, updateGroupStart } from '../../../store/redux-slices/admins'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'

const useCreateGroup = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isTenant = window.location.pathname.includes('tenant')
  const { groupDetail, groupLoading, adminDetails } = useSelector(state => state.admins)
  const pathName = window.location.pathname
  const { groupId } = useParams()
  const type = pathName.includes('create') ? 'create' : pathName.includes('edit') ? 'edit' : 'view'

  useEffect(() => {
    if (['edit', 'view'].includes(type)) {
      dispatch(getGroupDetailStart({ groupId, isTenant }))
    }
  }, [type, groupId])

  useEffect(() => {
    !isTenant && dispatch(getSuperAdminDetailsStart({ isTenant: false }))
  }, [])

  const handleSave = (formValues) => {
    dispatch(updateGroupStart({ data: { ...formValues, groupId: parseInt(groupId) }, isTenant, type, navigate }))
  }

  return {
    groupDetail: type === 'create' ? {} : groupDetail,
    type,
    groupLoading,
    adminDetails,
    handleSave,
    navigate,
    isTenant
  }
}

export default useCreateGroup
