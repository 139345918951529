import React from 'react'
import {
  faArrowCircleDown,
  faArrowCircleUp
} from '@fortawesome/pro-solid-svg-icons'
import PaginationComponent from '../Pagination'
import { Table } from '@themesberg/react-bootstrap'
import { getDateTime } from '../../utils/dateFormatter'
import {
  allTransactionTableHeaders,
  allTransactionType,
  statusType
} from '../../pages/Super-Admin/TransactionsBanking/constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AMOUNT_TYPE } from '../../pages/Super-Admin/CasinoTransactions/constants'
import sortArrowIcon from '../SortArrowsIcon'

const AllTransactionsList = ({
  setPage,
  page,
  limit,
  setLimit,
  transactions,
  loading = false,
  userDetail = false,
  allTransactionsTotalPages,
  sortBy,
  setSortBy,
  over,
  setOver
}) => {
  const getAfterBalance = (
    amount,
    beforeBalance,
    transactionStatus,
    afterBalance,
    currencyCode,
    transactionType,
    status
  ) => {
    if (transactionStatus === 'casino' && afterBalance) {
      return `${afterBalance?.toFixed(2)} ${currencyCode}`
    } else if (transactionType === 'bonusForfeit') {
      return `0 ${currencyCode}`
    } else if ((['withdrawInternal', 'withdraw'].includes(transactionType) && [1, 7].includes(status)) || ['removeMoneyInternal', 'removeMoney'].includes(transactionType)) {
      return `${(beforeBalance - Math.abs(amount))?.toFixed(
        2
      )} ${currencyCode}`
    } else if (status === 0) {
      return `${beforeBalance?.toFixed(2)} ${currencyCode}`
    } else {
      return `${(beforeBalance + amount)?.toFixed(2)} ${currencyCode}`
    }
  }

  const getBonusId = (transactionType, bonusId) => {
    const bonusTypes = [
      'bonusZeroedOut',
      'bonusExpired',
      'bonusToCash',
      'bonus',
      'bonusForfeit'
    ]
    if (bonusId && bonusTypes.includes(transactionType)) {
      return <>({bonusId})</>
    }
  }

  // const sortArrowIcon = (c) => {
  //   return (
  //     <>
  //       {c === 'Amount' &&
  //         (sortBy.amount === 'asc'
  //           ? (
  //             <FontAwesomeIcon
  //               className='ms-2'
  //               style={over.amount ? { color: 'red' } : {}}
  //               icon={faArrowCircleUp}
  //               onClick={() => {
  //                 setSortBy({ ...sortBy, amount: 'desc', isAmountSorting: true })
  //                 setOver({ ...over, amount: false })
  //               }}
  //               onMouseOver={() => setOver({ ...over, amount: true })}
  //               onMouseLeave={() => setOver({ ...over, amount: false })}
  //             />
  //             )
  //           : (
  //             <FontAwesomeIcon
  //               className='ms-2'
  //               style={over.amount ? { color: 'red' } : {}}
  //               icon={faArrowCircleDown}
  //               onClick={() => {
  //                 setSortBy({ ...sortBy, amount: 'asc', isAmountSorting: true })
  //                 setOver({ ...over, amount: false })
  //               }}
  //               onMouseOver={() => setOver({ ...over, amount: true })}
  //               onMouseLeave={() => setOver({ ...over, amount: false })}
  //             />
  //             ))}
  //       {c === 'iD' &&
  //         (sortBy?.Id === 'asc'
  //           ? (
  //             <FontAwesomeIcon
  //               className='ms-2'
  //               style={over.Id ? { color: 'red' } : {}}
  //               icon={faArrowCircleUp}
  //               onClick={() => {
  //                 setSortBy({ ...sortBy, Id: 'desc', isAmountSorting: false })
  //                 setOver({ ...over, Id: false })
  //               }}
  //               onMouseOver={() => setOver({ ...over, Id: true })}
  //               onMouseLeave={() => setOver({ ...over, Id: false })}
  //             />
  //             )
  //           : (
  //             <FontAwesomeIcon
  //               className='ms-2'
  //               style={over.Id ? { color: 'red' } : {}}
  //               icon={faArrowCircleDown}
  //               onClick={() => {
  //                 setSortBy({ ...sortBy, Id: 'asc', isAmountSorting: false })
  //                 setOver({ ...over, Id: false })
  //               }}
  //               onMouseOver={() => setOver({ ...over, Id: true })}
  //               onMouseLeave={() => setOver({ ...over, Id: false })}
  //             />
  //             ))}
  //     </>
  //   )
  // }

  return (
    <>
      {/* Table with tenants info */}

      <Table
        bordered
        striped
        responsive
        hover
        size='sm'
        className='text-center mt-4'
      >
        <thead className='thead-dark'>
          <tr>
            {allTransactionTableHeaders.map(
              (h, idx) =>
                (userDetail ? h.label !== 'Country' : true) && (
                  <th key={`T-table_heading ${idx}`}>
                    {h.label}
                    {sortArrowIcon(h.label)}
                  </th>
                )
            )}
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {transactions &&
            !loading &&
            transactions?.rows?.map(
              ({
                id,
                amount,
                currencyCode,
                transactionType,
                type: transactionStatus,
                updatedAt,
                beforeBalance,
                amountType,
                afterBalance,
                status,
                moreDetails,
                actioneeType
              }) => {
                return (
                  <tr key={`transaction-list ${id}`}>
                    <td>{transactionStatus === 'casino' && id ? id : 'NA'}</td>
                    <td>{transactionStatus !== 'casino' && id ? id : 'NA'}</td>
                    <td>
                      {allTransactionType?.find(
                        ({ value }) => value === transactionType
                      )?.label || 'NA'}
                      {getBonusId(transactionType, moreDetails?.bonusId)}
                    </td>
                    <td>{`${amount.toFixed(2)} ${currencyCode}`}</td>
                    <td>
                      {statusType?.find((type) => type.value === status)?.label}
                    </td>
                    <td>{AMOUNT_TYPE[amountType]?.label || 'NA'}</td>
                    <td>{`${beforeBalance?.toFixed(2)} ${currencyCode}`}</td>
                    <td>
                      {getAfterBalance(
                        amount,
                        beforeBalance,
                        transactionStatus,
                        afterBalance,
                        currencyCode,
                        transactionType,
                        status
                      )}
                    </td>
                    <td>
                      {moreDetails?.updatedBy
                        ? moreDetails?.updatedBy
                        : actioneeType || 'NA'}
                    </td>
                    <td>{updatedAt ? getDateTime(updatedAt) : 'NA'}</td>
                  </tr>
                )
              }
            )}

          {transactions?.count === 0 && !loading && (
            <tr>
              <td colSpan={10} className='text-danger text-center'>
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {transactions?.count > 0 && !loading && (
        <PaginationComponent
          page={transactions?.count < page ? setPage(1) : page}
          totalPages={allTransactionsTotalPages}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
        />
      )}
    </>
  )
}

export default AllTransactionsList
