import { createSlice } from '@reduxjs/toolkit'

const {
  actions: {
    getAllCredKeysStart,
    getAllCredKeysSuccess,
    getAllCredKeysFailure,
    getAllCredStart,
    getAllCredSuccess,
    getAllCredFailure,
    createCredsStart,
    createCredsSuccess,
    createCredsFailure,
    getCredByKeyStart,
    getCredByKeySuccess,
    getCredByKeyFailure,
    updateCredsStart,
    updateCredsSuccess,
    updateCredsFailure,
    updateTenantThemeStart,
    updateTenantThemeSuccess,
    updateTenantThemeFailure,
    getAllThemesStart,
    getAllThemesSuccess,
    getAllThemesFailure,
    getAllBannersStart,
    getAllBannersSuccess,
    getAllBannersFailure,
    uploadBannerStart,
    uploadBannerComplete,
    getAffiliateKeysSuccess,
    updateAffiliateSystemStart,
    updateAffiliateSystemComplete
  },
  reducer
} = createSlice({
  name: 'credentials',
  initialState: {
    loading: false,
    error: null,
    success: false,
    data: null,
    credData: null,
    credKeys: null,
    allThemesData: null,
    banners: null,
    affiliateKeys: null
  },
  reducers: {
    getAllCredKeysStart: (state) => ({
      ...state,
      loading: true
    }),
    getAllCredKeysSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      credKeys: payload
    }),
    getAllCredKeysFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    getAllCredStart: (state) => ({
      ...state,
      loading: true
    }),
    getAllCredSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      data: payload
    }),
    getAllCredFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    createCredsStart: (state) => ({
      ...state,
      loading: true
    }),
    createCredsSuccess: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: true
    }),
    createCredsFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    getCredByKeyStart: (state) => ({
      ...state,
      loading: true
    }),
    getCredByKeySuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      credData: payload
    }),
    getCredByKeyFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    updateCredsStart: (state) => ({
      ...state,
      loading: true
    }),
    updateCredsSuccess: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: true
    }),
    updateCredsFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    updateTenantThemeStart: (state) => ({
      ...state,
      loading: true,
      error: null,
      success: false
    }),
    updateTenantThemeSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true
    }),
    updateTenantThemeFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    getAllThemesStart: (state) => ({
      ...state,
      loading: true,
      error: null,
      success: false
    }),

    getAllThemesSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      allThemesData: payload
    }),
    getAllThemesFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    getAllBannersStart: (state) => ({
      ...state,
      loading: true
    }),
    getAllBannersSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      banners: payload
    }),
    getAllBannersFailure: (state) => ({
      ...state,
      loading: false
    }),
    uploadBannerStart: (state) => ({
      ...state,
      loading: true
    }),
    uploadBannerComplete: (state) => ({
      ...state,
      loading: false
    }),
    getAffiliateKeysSuccess: (state, { payload }) => ({
      ...state,
      affiliateKeys: payload
    }),
    updateAffiliateSystemStart: (state) => ({
      ...state,
      loading: true
    }),
    updateAffiliateSystemComplete: (state) => ({
      ...state,
      loading: false
    })
  }
})

export default reducer

export {
  getAllCredKeysStart,
  getAllCredKeysSuccess,
  getAllCredKeysFailure,
  getAllCredStart,
  getAllCredSuccess,
  getAllCredFailure,
  createCredsStart,
  createCredsSuccess,
  createCredsFailure,
  getCredByKeyStart,
  getCredByKeySuccess,
  getCredByKeyFailure,
  updateCredsStart,
  updateCredsSuccess,
  updateCredsFailure,
  updateTenantThemeStart,
  updateTenantThemeSuccess,
  updateTenantThemeFailure,
  getAllThemesStart,
  getAllThemesSuccess,
  getAllThemesFailure,
  getAllBannersStart,
  getAllBannersSuccess,
  getAllBannersFailure,
  uploadBannerStart,
  uploadBannerComplete,
  getAffiliateKeysSuccess,
  updateAffiliateSystemStart,
  updateAffiliateSystemComplete
}
