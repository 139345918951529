import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'

import store from './store'

// core styles
import './scss/volt.scss'

// vendor styles
import 'react-datetime/css/react-datetime.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import 'react-quill/dist/quill.snow.css'

import HomePage from './pages/HomePage'
import { setupInterceptors } from './utils/axios'

setupInterceptors()

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <Provider store={store}>
    <ToastContainer
      theme='colored'
      position='top-right'
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
    <BrowserRouter>
      <HomePage />
    </BrowserRouter>
  </Provider>
)
