import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useDidMountEffect } from '../../../../utils/useDidMountEffect'
import { getBannedGamesListDetailStart } from '../../../../store/redux-slices/superAdminTransactions'

const useBannedGamesDetails = () => {
  const { loading, getBannedGamesListDetail } = useSelector((state) => state.superAdminTransactions)
  const [selectedProvider, setSelectedProvider] = useState('')
  const [limit, setLimit] = useState(15)
  const [pageNo, setPageNo] = useState(1)
  const [listBannedGames, setListBannedGames] = useState([])
  const { bannedGameId } = useParams()
  const dispatch = useDispatch()
  const [search, setSearch] = useState('')
  const isInitialRender = useDidMountEffect()
  const url = window.location.href
  const isTenant = url.includes('/tenant/')
  const isClone = url.includes('/clone/')
  const totalPages = Math.ceil(getBannedGamesListDetail?.gameDetail?.count / limit)

  const fetchDetails = () => {
    dispatch(getBannedGamesListDetailStart({
      isTenant,
      bannedGamesListId: bannedGameId,
      limit,
      pageNo,
      providerId: selectedProvider,
      search: search.replace(/\s/g, '')
    }))
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        if (pageNo === 1) {
          dispatch(getBannedGamesListDetailStart({
            isTenant,
            bannedGamesListId: bannedGameId,
            limit,
            pageNo,
            providerId: selectedProvider,
            selectedProvider,
            search: search.replace(/\s/g, '')
          }))
        } else {
          setPageNo(1)
        }
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  useEffect(() => {
    fetchDetails()
  }, [selectedProvider, pageNo, limit])

  useEffect(() => {
    if (getBannedGamesListDetail?.gameDetail?.rows) {
      setListBannedGames(getBannedGamesListDetail.gameDetail.rows)
    }
  }, [getBannedGamesListDetail, selectedProvider])

  return {
    loading,
    getBannedGamesListDetail,
    selectedProvider,
    setSelectedProvider,
    setLimit,
    setPageNo,
    totalPages,
    limit,
    pageNo,
    search,
    setSearch,
    isTenant,
    isClone,
    listBannedGames,
    setListBannedGames
  }
}

export default useBannedGamesDetails
