import React from 'react'
import General from './General'
import Country from './Country'
import CurrenciesForm from './CurrencyForm'
import RestrictPlayers from './RestrictPlayers'
import useEditProvider from '../hooks/useEditProvider'
import { Tab, Tabs } from '@themesberg/react-bootstrap'
import Preloader from '../../../../components/Preloader'
import { faCircleArrowLeft } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SuperAdminRoutes, TenantRoutes } from '../../../../routes'

const EditProvider = () => {
  const {
    data,
    state,
    search,
    setData,
    loading,
    adminId,
    details,
    isTenant,
    navigate,
    tenantId,
    logoCheck,
    countries,
    setSearch,
    updateData,
    providerId,
    amtOptions,
    myCountries,
    kycCountries,
    setLogoCheck,
    submitLoading,
    selectedKeyTab,
    providerDetails,
    tenantLanguages,
    setKycCountries,
    setSelectedKeyTab,
    recommendedDeposit,
    recommendedWithdraw,
    setRecommendedDeposit,
    setRecommendedWithdraw,
    transactionPaymentCategories
  } = useEditProvider()

  return (
    <>
      {loading
        ? (
          <Preloader />
          )
        : (
          <>
            <div className='d-flex align-items-center'>
              <FontAwesomeIcon
                icon={faCircleArrowLeft}
                className='cursor-pointer'
                onClick={() => navigate(isTenant ? TenantRoutes.PaymentAggregators : SuperAdminRoutes.PaymentAggregators, { state: { ...state, adminId, tenantId } })}
              />
                &nbsp;
              <h3 className='mb-0'>
                {details ? 'View' : 'Edit'} Method : {providerDetails?.name}
              </h3>
            </div>

            <Tabs
              activeKey={selectedKeyTab}
              onSelect={(tab) => setSelectedKeyTab(tab)}
              className='nav-light'
            >
              <Tab eventKey='general' title='General'>
                <div className='mt-3'>
                  {parseInt(providerDetails?.paymentProviderId) ===
                  parseInt(providerId) && (
                    <General
                      details={details}
                      logoCheck={logoCheck}
                      amtOptions={amtOptions}
                      updateData={updateData}
                      setLogoCheck={setLogoCheck}
                      tenantLanguages={tenantLanguages}
                      providerDetails={providerDetails}
                      transactionPaymentCategories={transactionPaymentCategories}
                    />
                  )}
                </div>
              </Tab>
              <Tab eventKey='country' title='Countries'>
                <div className='mt-4'>
                  <Country
                    cashier
                    data={data}
                    search={search}
                    setData={setData}
                    details={details}
                    navigate={navigate}
                    isTenant={isTenant}
                    setSearch={setSearch}
                    countries={countries}
                    updateData={updateData}
                    loading={submitLoading}
                    myCountries={myCountries}
                    kycCountries={kycCountries}
                    providerDetails={providerDetails}
                    setKycCountries={setKycCountries}
                    recommendedDeposit={recommendedDeposit}
                    recommendedWithdraw={recommendedWithdraw}
                    setRecommendedDeposit={setRecommendedDeposit}
                    setRecommendedWithdraw={setRecommendedWithdraw}
                  />
                </div>
              </Tab>
              <Tab eventKey='currency' title='Currency'>
                <div className='mt-4'>
                  <CurrenciesForm
                    details={details}
                    tenantId={tenantId}
                    updateData={updateData}
                    providerDetails={providerDetails}
                  />
                </div>
              </Tab>

              {providerDetails &&
              (providerDetails?.supportsWithdrawal ||
                providerDetails?.supportsDeposit) && (
                  <Tab eventKey='restrict' title='Restrict'>
                    <div className='mt-4'>
                      <RestrictPlayers
                        state={state}
                        details={details}
                        isTenant={isTenant}
                        updateData={updateData}
                        providerDetails={providerDetails}
                      />
                    </div>
                  </Tab>
              )}
            </Tabs>
          </>
          )}
    </>
  )
}

export default EditProvider
