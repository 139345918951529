import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getGroupsStart } from '../../../store/redux-slices/admins'
import useCheckPermission from '../../../utils/checkPermission'
import { useNavigate } from 'react-router-dom'
import useDidMountEffect from '../../../utils/useDidMountEffect'

const useGroups = () => {
  const { groupsList, groupLoading } = useSelector(state => state.admins)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isTenant = window.location.pathname.includes('tenant')
  const [groupName, setGroupName] = useState('')
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const totalPages = Math.ceil(groupsList?.count / limit)
  const { isHidden } = useCheckPermission()
  const isInitialRender = useDidMountEffect()

  const getGroups = () => dispatch(getGroupsStart({ groupName, isTenant, pageNo: page, limit }))

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        if (page === 1) {
          getGroups()
        } else {
          setPage(1)
        }
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [groupName])

  useEffect(() => {
    !isInitialRender && getGroups()
  }, [page])

  useEffect(() => {
    setPage(1)
    getGroups()
  }, [limit])

  return {
    groupsList,
    limit,
    setLimit,
    page,
    setPage,
    groupName,
    setGroupName,
    totalPages,
    isHidden,
    groupLoading,
    navigate,
    isTenant
  }
}

export default useGroups
