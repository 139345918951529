import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getAllTABonusStart, updateTABonusStatusStart } from '../../../../store/redux-slices/bonus'
import { safeStringify } from '../../../../utils/helpers'
import { useDidMountEffect } from '../../../../utils/useDidMountEffect'

const useBonus = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isInitialRender = useDidMountEffect()

  const { bonusList, loading } = useSelector((state) => state.bonus)
  const [show, setShow] = useState(false)
  const [active, setActive] = useState('')
  const [bonusId, setBonusId] = useState(false)
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [bonusTyp, setBonusTyp] = useState('')
  const [search, setSearch] = useState('')
  const [isActive, setIsActive] = useState('')
  const totalPages = Math.ceil(bonusList?.count / limit)
  const [name, setName] = useState()
  const [showGiveModal, setShowGiveModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showFreeSpinModal, setShowFreeSpinModal] = useState(false)
  const [searchByBonusId, setSearchByBonusId] = useState('')

  const handleShow = (id, active, myName) => {
    setBonusId(id)
    setActive(!active)
    setShow(true)
    setName(myName)
  }

  const handleYes = () => {
    dispatch(
      updateTABonusStatusStart({
        data: {
          code: 'BONUS',
          status: active,
          bonusId
        },
        limit,
        pageNo: page,
        search,
        bonusType: bonusTyp === '' ? '' : safeStringify([bonusTyp]),
        isActive
      })
    )
    setShow(false)
  }

  useEffect(() => {
    !isInitialRender && dispatch(getAllTABonusStart({
      limit,
      pageNo: page,
      search,
      bonusType: bonusTyp === '' ? '' : safeStringify([bonusTyp]),
      isActive,
      bonusId: searchByBonusId
    }))
  }, [page])

  useEffect(() => {
    setPage(1)
    !showGiveModal && !showDeleteModal && !showFreeSpinModal && dispatch(getAllTABonusStart({
      limit,
      pageNo: page,
      search,
      bonusType: bonusTyp === '' ? '' : safeStringify([bonusTyp]),
      isActive,
      bonusId: searchByBonusId
    }))
  }, [limit, bonusTyp, isActive, showGiveModal, showDeleteModal, showFreeSpinModal])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        if (page === 1) {
          dispatch(getAllTABonusStart({
            limit,
            pageNo: page,
            search,
            bonusType: bonusTyp === '' ? '' : safeStringify([bonusTyp]),
            isActive,
            bonusId: searchByBonusId
          })
          )
        } else {
          setPage(1)
        }
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search, searchByBonusId])

  return {
    navigate,
    loading,
    bonusList,
    handleShow,
    handleYes,
    show,
    setShow,
    active,
    limit,
    setLimit,
    setPage,
    page,
    totalPages,
    bonusTyp,
    setBonusTyp,
    search,
    setSearch,
    isActive,
    setIsActive,
    name,
    showGiveModal,
    setShowGiveModal,
    showDeleteModal,
    setShowDeleteModal,
    showFreeSpinModal,
    setShowFreeSpinModal,
    searchByBonusId,
    setSearchByBonusId
  }
}

export default useBonus
