import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getGlobalRegistrationStart, updateGlobalRegistrationStart } from '../../../store/redux-slices/fetchData'
import { checkIsTenant } from '../../../utils/constants'
import { getUserFieldsStart, updateUserFieldsStart } from '../../../store/redux-slices/tenantUsers'

const useFormFields = () => {
  const dispatch = useDispatch()

  const { formFields, loading } = useSelector((state) => state.fetch)
  const { userFields, loading: TAloading } = useSelector((state) => state.tenantUsers)

  const [data, setData] = useState(null)

  const handleChange = (e, check) => {
    if (!checkIsTenant()) {
      setData({
        ...data,
        [e.target.name]: e.target.name !== 'phoneVerification' ? (e.target.checked ? 2 : 0) : e.target.checked
      })
    } else {
      if (check === '') {
        setData({
          ...data,
          [e.target.name]: e.target.name !== 'phoneVerification' ? (e.target.checked ? 1 : 0) : e.target.checked
        })
      } else {
        setData({
          ...data,
          [e.target.name]: e.target.name !== 'phoneVerification' ? (check ? 2 : 1) : e.target.checked
        })
      }
    }
  }

  const updateFields = () => {
    dispatch(checkIsTenant() ? updateUserFieldsStart(data) : updateGlobalRegistrationStart({ data }))
  }

  useEffect(() => {
    setData(checkIsTenant() ? userFields : formFields)
  }, [formFields, userFields])

  useEffect(() => {
    dispatch(checkIsTenant() ? getUserFieldsStart() : getGlobalRegistrationStart())
  }, [])

  return {
    formFields,
    loading: checkIsTenant() ? TAloading : loading,
    data,
    setData,
    handleChange,
    updateFields
  }
}

export default useFormFields
