import { Form, Table } from '@themesberg/react-bootstrap'
import React from 'react'

const GamesTable = ({
  search,
  setSearch,
  gameIds,
  availableGames,
  handleSelectAll,
  loading,
  handleSelect,
  getData
}) => {
  return (
    <>
      <Form.Control
        type='search'
        name='search'
        placeholder='Search Game'
        className='w-25'
        size='sm'
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <div className='d-flex mt-2'>
        <div style={{ overflow: 'auto', maxHeight: '400px', width: '60%', marginRight: '10px' }}>
          <Table size='sm' className='text-center'>
            <thead>
              <tr className='thead-dark'>
                <th>
                  <input
                    name='selectAll'
                    type='checkbox'
                    checked={gameIds?.length >= availableGames?.length}
                    onChange={(e) => handleSelectAll(e)}
                  />
                </th>
                <th>Game Id</th>
                <th>Game Name</th>
              </tr>
            </thead>

            <tbody>
              {loading &&
                <tr>
                  <td />
                  <td id='inline-spinner' />
                  <td />
                </tr>}
              {availableGames?.length > 0 && !loading &&
                availableGames?.map(
                  ({
                    masterCasinoGameId,
                    name
                  }) => {
                    return (
                      <tr key={`games-list ${masterCasinoGameId}`}>
                        <td>
                          <input
                            name={masterCasinoGameId}
                            type='checkbox'
                            checked={gameIds?.includes(masterCasinoGameId)}
                            value={masterCasinoGameId}
                            onChange={(e) => handleSelect(e, masterCasinoGameId)}
                          />
                        </td>
                        <td>{masterCasinoGameId}</td>
                        <td>{name}</td>
                      </tr>
                    )
                  }
                )}
              {availableGames?.count === 0 && !loading && (
                <tr>
                  <td colSpan={2} className='text-danger text-center'>
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
        <div style={{ overflow: 'auto', maxHeight: '400px', width: '40%' }}>
          <Table size='sm' className='text-center'>
            <thead>
              <tr className='thead-dark'>
                <th>Selected Games</th>
              </tr>
            </thead>
            <tbody>
              {gameIds?.length > 0 &&
                gameIds?.map(
                  (gameId) => {
                    return (
                      <tr key={`games-list ${gameId}`}>
                        <td>{getData('name', gameId)} ( GameId: {getData('id', gameId)} )</td>
                      </tr>
                    )
                  }
                )}
              {gameIds?.length === 0 && (
                <tr>
                  <td colSpan={1} className='text-danger text-center'>
                    No Games Selected
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  )
}

export default GamesTable
